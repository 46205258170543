// material-ui
import * as React from 'react';

import LinearProgress from '@mui/material/LinearProgress';

// ==============================|| LOADER ||============================== //
function Loader() {
  return (
    <LinearProgress
      sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1301, width: '100%' }}
      color="warning"
    />
  );
}

export default Loader;
