import * as React from 'react';
import propTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function SectionHeadline(props) {
  const { alwaysCenter = false, description, id, inverted = false, overline, title } = props;
  return (
    <Box sx={{ m: alwaysCenter ? 'auto' : 'none' }}>
      {overline && (
        <Typography
          id={id}
          component="h2"
          variant="body2"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            mb: 1,
            ...(alwaysCenter && {
              textAlign: 'center',
            }),
          }}
        >
          {overline}
        </Typography>
      )}
      {typeof title === 'string' ? (
        <Typography
          variant="h2"
          sx={{
            maxWidth: 500,
            ...(inverted
              ? {
                  color: '#fff',
                }
              : {
                  color: 'primaryDark.900',
                }),
            ...(alwaysCenter && {
              textAlign: 'center',
              maxWidth: '100%',
            }),
          }}
        >
          {title}
        </Typography>
      ) : (
        React.cloneElement(title, {
          style: {
            maxWidth: 500,
            ...(alwaysCenter && {
              maxWidth: '100%',
              textAlign: 'center',
            }),
            ...(inverted && {
              color: '#fff',
            }),
          },
        })
      )}
      {description && (
        <Typography
          sx={{
            mt: 1,
            mb: 3,
            maxWidth: 500,
            ...(inverted
              ? {
                  color: 'grey.400',
                }
              : {
                  color: 'grey.800',
                }),
            ...(alwaysCenter && {
              textAlign: 'center',
              mx: 'auto',
            }),
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}

SectionHeadline.propTypes = {
  alwaysCenter: propTypes.bool,
  description: propTypes.any,
  id: propTypes.string,
  inverted: propTypes.bool,
  overline: propTypes.any,
  title: propTypes.any,
};

export default SectionHeadline;
