import React from 'react';

import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: 'initial',
  minWidth: 0,
  letterSpacing: 0.5,
  margin: theme.spacing(0, 2),
  padding: 0,
  overflow: 'unset',
  fontWeight: 500,
  '&:hover::before': {
    backgroundColor: 'rgba(0 0 0 / 0.05)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '-1rem',
    right: '-1rem',
    height: '100%',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 50,
  },
}));

export default React.memo(TabItem);
