import React from 'react';
import dayjs from '@/utils/dayJs';
import propTypes from 'prop-types';
import { LeagueLogo } from '@/views/Home/Competition/League';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AccessTime from '@mui/icons-material/AccessTime';

function LiveChip({ label }) {
  return (
    <Typography fontWeight="bold" color="warning">
      {label}
    </Typography>
  );
}

const Status = React.memo(({ status, time }) => {
  if (status === 'cm') {
    return 'FT';
  }

  if (status === 'STATUS_POSTPONED') {
    return 'Postp.';
  }

  if (status === 'in') {
    return <LiveChip label={time || 'Live'} />;
  }

  return <AccessTime fontSize="small" />;
});

Status.propTypes = {
  status: propTypes.string.isRequired,
};

function Time({ status, isH2H, isTop, slug, date }) {
  const {
    tp: { cm, st, n, dsc },
    dt,
    dc,
  } = status;

  const statusText = React.useMemo(
    () => (cm && 'cm') || (st === 'in' && st) || (n === 'STATUS_POSTPONED' && n) || 'pre',
    [cm, st, n]
  );

  return (
    <Typography
      color="textSecondary"
      justifyContent="center"
      alignItems="center"
      component={Grid}
      variant="body2"
      item
      container
      direction="column"
      xs={isH2H ? 4 : 2}
      sm={isH2H ? 2.5 : 2}
      spacing={0.5}
      noWrap
    >
      {isTop || isH2H ? (
        <LeagueLogo logo={slug} name={slug} />
      ) : (
        <Status time={dc || dt || dsc} status={statusText} />
      )}
      {isH2H && (
        <Typography color="textSecondary" component={Grid} item variant="caption">
          {dayjs(date).format('DD MMM YYYY')}
        </Typography>
      )}
    </Typography>
  );
}

Time.propTypes = {
  date: propTypes.string,
  isH2H: propTypes.bool,
  isTop: propTypes.bool,
  slug: propTypes.string,
  status: propTypes.shape({
    cl: propTypes.string.isRequired,
    dc: propTypes.string.isRequired,
    p: propTypes.string.isRequired,
    tp: {
      cm: propTypes.bool.isRequired,
      st: propTypes.string.isRequired,
      n: propTypes.string.isRequired,
    },
  }),
};

export default React.memo(Time);
