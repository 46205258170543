import React from 'react';
import propTypes from 'prop-types';

import Grid from '@mui/material/Grid2';

import TeamLogo from './TeamLogo';
import TeamName from './TeamName';

function Team({ team, isWinner }) {
  return (
    <Grid container wrap="nowrap" alignItems="center" spacing={2} alignSelf="flex-start">
      <TeamLogo src={team.i} alt={team.t.sd} />
      <TeamName isWinner={isWinner} name={team.t.sd} />
    </Grid>
  );
}

Team.propTypes = {
  isWinner: propTypes.bool,
  team: propTypes.object.isRequired,
};

export default React.memo(Team);
