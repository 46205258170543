import React from 'react';
import Image from 'mui-image';
import dayjs from '@/utils/dayJs';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

export default function NewsSidebarItem(post) {
  const { title } = post; // ?.rendered;
  const image = post.images?.thumb || post.images?.large;

  return (
    <ListItem key={title} button component={Link} to={`/news/${post.id}`} title={title} divider>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: image && !post.isLarge ? '85px 1fr' : '1fr',
          gridAutoRows: 'max-content',
          gridTemplateRows: 'max-content',
          gap: 2,
          py: 0.5,
        }}
      >
        {image && (
          <Image
            width="100%"
            height={post.isLarge ? '150px' : '85px'}
            sx={{
              borderRadius: '12px',
              objectFit: 'cover',
            }}
            showLoading={
              <Skeleton
                sx={{
                  borderRadius: '12px',
                }}
                width="100%"
                height={post.isLarge ? '150px' : '85px'}
              />
            }
            alt={title}
            src={image}
          />
        )}
        <Box display="flex" flexDirection="column">
          <Typography
            variant="subtitle2"
            component="h3"
            color="text.primary"
            sx={{
              overflow: 'hidden',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
            }}
          >
            {title}
          </Typography>
          <Typography color="grey.500" variant="caption">
            {post.provider} •{' '}
            {dayjs(post.publish_time)
              .fromNow()
              .replace('hours', 'hr.')
              .replace('hour', 'hr.')
              .replace('minutes', 'min.')}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
}
