import * as React from 'react';

import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimerIcon from '@mui/icons-material/Timer';
import Typography from '@mui/material/Typography';
import { red, yellow } from '@mui/material/colors';
import SportsIcon from '@mui/icons-material/Sports';
import TimelineContent from '@mui/lab/TimelineContent';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import CardIcon from './Lineup/Player/CardIcon';

export default function GameTimeline({ kvts, isHome }) {
  const getTimeLineIcon = React.useCallback((id) => {
    switch (id) {
      case '93':
        return <CardIcon color={red[500]} />;
      case '94':
        return <CardIcon color={yellow[500]} />;
      case '70':
      case '137':
        return <SportsSoccerIcon />;
      case '76':
        return <SwapVertIcon />;
      case '80':
      case '81':
      case '82':
      case '83':
        return <TimerIcon />;
      default:
        return <SportsIcon />;
    }
  }, []);
  return (
    <Paper>
      <Timeline position="alternate">
        {kvts.map((kvt, index) => (
          <TimelineItem
            key={`event${index + kvt.type.id}`}
            position={isHome(kvt.team?.id) ? 'left' : 'right'}
          >
            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2">
              {kvt.clock.displayValue}
            </TimelineOppositeContent>
            <TimelineSeparator>
              {index > 0 && <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />}
              <TimelineDot sx={{ bgcolor: 'unset', boxShadow: 'unset' }}>
                {getTimeLineIcon(kvt.type.id)}
              </TimelineDot>
              {index !== kvts.length - 1 && (
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
              )}
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="subtitle2" component="span">
                {kvt.type.text}
              </Typography>
              <Typography component="div" variant="caption">
                {(kvt.type.id === '76' ? kvt.text : kvt.shortText) || kvt.text}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Paper>
  );
}
