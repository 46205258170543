import * as React from 'react';
import propTypes from 'prop-types';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';

const map = {
  white: {
    light: 'common.white',
    dark: 'primaryDark.900',
  },
  comfort: {
    light: 'grey.50',
    dark: 'primaryDark.900',
  },
  dim: {
    light: 'primaryDark.700',
    dark: 'primaryDark.700',
  },
  transparent: {
    light: 'transparent',
    dark: 'transparent',
  },
};

const Section = React.forwardRef((props, ref) => {
  const { bg = 'white', children, sx, cozy = false, noPaddingBottom = false, ...other } = props;

  return (
    <Box
      ref={ref}
      {...other}
      sx={[
        (theme) => ({
          ...(bg === 'gradient'
            ? {
                background: `linear-gradient(${
                  theme.palette.background.paper
                } 0%, ${alpha(theme.palette.background.paper, 0.2)} 100%)`,
              }
            : {
                bgcolor: map[bg].dark,
              }),
          py: cozy ? { xs: 6, sm: 10, md: 12 } : { xs: 4, sm: 12, md: 14 },
          pb: noPaddingBottom ? '0 !important' : undefined,
          overflow: 'hidden',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Container>{children}</Container>
    </Box>
  );
});

Section.propTypes = {
  bg: propTypes.string,
  children: propTypes.any,
  cozy: propTypes.bool,
  noPaddingBottom: propTypes.bool,
  sx: propTypes.any,
};

export default Section;
