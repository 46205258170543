import React from 'react';

import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

function Footer() {
  return (
    <Card
      component="footer"
      sx={{
        padding: '40px',
        borderRadius: '0px !important',
        border: 0,
      }}
    >
      <CardContent>
        <Typography component="h2" variant="h5" gutterBottom>
          Soccer-100.com: The Ultimate Soccer Streaming Platform for Live Matches Worldwide
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          Soccer-100.com is your go-to website for live streaming the biggest and most popular
          soccer matches from around the globe. Offering high-quality streams of major football
          events, Soccer-100.com covers not only soccer but also other top sports such as
          basketball, NFL, MMA, and more. As one of the most trusted alternatives to Reddit Soccer
          Streams, it’s the perfect destination for sports fans looking for hassle-free, ad-free
          streaming.
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          Why Choose Soccer-100.com?
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          Soccer-100.com provides a comprehensive streaming experience for soccer lovers who want to
          watch their favorite teams without the frustration of constant ads, blackouts, or
          expensive cable subscriptions. Whether you are a fan of the English Premier League, La
          Liga, Serie A, Bundesliga, or international tournaments like the UEFA Champions League,
          Copa del Rey, or the World Cup, Soccer-100.com delivers the best coverage in real time.
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          Key Features of Soccer-100.com:
        </Typography>
        <Typography variant="body2" gutterBottom>
          <List>
            <ListItem>
              <strong>Live Soccer Streams</strong>: Watch 100+ live soccer matches daily from top
              leagues worldwide, including the English Premier League, Serie A, La Liga, Bundesliga,
              Ligue 1, and more.
            </ListItem>
            <ListItem>
              <strong>Top Football Tournaments</strong>: Stream international football events like
              the UEFA Champions League, Europa League, Copa América, Euro Cup, and more.
            </ListItem>
            <ListItem>
              <strong>Multiple Sports Coverage</strong>: Enjoy streams not just for soccer, but also
              for NBA, NFL, MMA, and other popular sporting events.
            </ListItem>
            <ListItem>
              <strong>No Ads or Interruptions</strong>: Experience smooth, uninterrupted streams
              without the nuisance of ads or pop-ups that are common on other platforms.
            </ListItem>
            <ListItem>
              <strong>Comprehensive Stats & Updates</strong>: Stay updated with live scores, league
              tables, player stats, and goal highlights as they happen.
            </ListItem>
            <ListItem>
              <strong>Easy Access</strong>: No need for expensive cable subscriptions. Watch all
              your favorite matches online from your computer or laptop.
            </ListItem>
          </List>
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          Watch Top Teams Live:
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          From top European clubs like <strong>Manchester United</strong>,{' '}
          <strong>Liverpool</strong>, <strong>Real Madrid</strong>,<strong>Barcelona</strong>,{' '}
          <strong>PSG</strong>, <strong>Bayern Munich</strong>, and <strong>Juventus</strong>, to
          major international teams, Soccer-100.com lets you catch every key match live.
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          How to Stream on Soccer-100.com:
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          To start streaming on Soccer-100.com, simply sign up for a subscription. Once you’re
          registered, you’ll have access to the latest live feeds, match highlights, and
          up-to-the-minute coverage of every top-tier soccer event.
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          Why Soccer-100.com is the Best Reddit Soccer Streams Alternative:
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          Soccer-100.com has earned a reputation as one of the best alternatives to Reddit Soccer
          Streams and Soccer Thunder. With a user-friendly interface, minimal downtime, and
          high-definition streams, it provides the ultimate online viewing experience for soccer
          fans everywhere.
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          Enjoy Football Live Without the Hassles:
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          Say goodbye to blackouts, buffering, and intrusive ads. Soccer-100.com ensures you never
          miss out on any live action from your favorite football matches. Whether you are watching
          the <strong>Premier League</strong>, <strong>Serie A</strong>, <strong>La Liga</strong>,
          or international tournaments, Soccer-100.com is your best source for live soccer
          streaming.
        </Typography>
        <Typography variant="subtitle" component="p" gutterBottom>
          Join Soccer-100.com today and enjoy seamless access to live football action, score
          updates, and much more!
        </Typography>
      </CardContent>
      <Typography variant="subtitle" component="p" color="text.secondary" align="center">
        Copyright ©{' '}
        {/* <Link color="inherit" href="https://redly.dev/">
          Redly
        </Link> */}
        {new Date().getFullYear()}.
      </Typography>
    </Card>
  );
}

export default React.memo(Footer);
