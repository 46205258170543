import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    gameFilter: 0,
    search: '',
  },
  reducers: {
    setGameFilter: (state, action) => {
      state.gameFilter = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGameFilter, setSearch } = mainSlice.actions;

export default mainSlice.reducer;
