import React from 'react';
import Spinner from '@/components/Spinner';
import { usePostsQuery } from '@/store/apiSlice';
import NewsSideBarItem from '@/views/News/NewsSidebarItem';

import Grid from '@mui/material/Grid2';
import { List, Paper, ListSubheader } from '@mui/material';

function NewsSidebar() {
  const { data, isFetching, isSuccess } = usePostsQuery();

  const posts = data || []; // .slice().sort((a, b) => b.id - a.id);

  return (
    <Grid size={{ xs: 12, md: 3.5 }}>
      <List component={Paper} subheader={<ListSubheader component="div">Top News</ListSubheader>}>
        {isFetching && <Spinner />}

        {isSuccess &&
          !isFetching &&
          posts.map(({ id, ...rest }, index) => (
            <NewsSideBarItem key={id} id={id} {...rest} isLarge={!index} />
          ))}
      </List>
    </Grid>
  );
}

export default React.memo(NewsSidebar);
