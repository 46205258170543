import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container'; // import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";
import Footer from './Footer';
import Header from './Header'; // project imports

// project imports
// import FootyLight from "../../views/Match/FootyLight";

// const Sidebar = Loadable(lazy(() => import('./Sidebar')));
// const Header = Loadable(lazy(() => import('./Header')));
// const Footer = Loadable(lazy(() => import('./Footer')));
// const Alternative = Loadable(lazy(() => import('./Alternative')));
// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
  return (
    <Box>
      <Header />
      <Divider />
      {/* <AltenativeWebsites/> */}

      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Outlet />
      </Container>
      <Divider />
      <Footer />
      <ScrollRestoration />
    </Box>
  );
}

export default MainLayout;
