import * as React from 'react';
import Head from '@/components/Head';
import Page from '@/layout/MainLayout/Page';

import Typography from '@mui/material/Typography';
import LockRounded from '@mui/icons-material/LockRounded';

export function Component() {
  return (
    <Page avatar={<LockRounded />} title="DCMA" subheader="Digital Millennium Copyright Act">
      <Head title="DCMA - Soccerstreams100" description="Get In Touch with Streams100" />
      <Typography variant="body2" gutterBottom>
        A. All content on this website comes from sites such as search engines, social networks, and
        forums.These links and programs are free and accessible to everyone. SoccerStreams is not
        responsible for external websites content. If you find that some of the content violates
        your rights you may request for that content to be brought down at the host that is
        responsible for the content. That will ensure that the content is removed from SoccerStreams
        and other search engines that may have indexed the content.
      </Typography>
      <Typography variant="body2" gutterBottom>
        B. SoccerStreams DOES NOT MANAGE, HOST AND DOES NOT PUBLISH any audiovisual content on its
        servers. Only internet user’s share material present on third-party websites by linking or
        copying an embed code from other sites, assuming that they comply with its terms and
        conditions of use, including copyright.
      </Typography>
      <Typography variant="body2" gutterBottom>
        C. The logos, images and other content present on this website are the property of their
        respective owners.
      </Typography>
    </Page>
  );
}
