import React from 'react';

import Tabs, { tabsClasses } from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';

export const tabsSx = {
  [`& .${tabsClasses.indicator}`]: {
    height: 4,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    backgroundColor: '#635ee7',
  },
};

function TabsHead({ component, value, children }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Tabs
      component={component}
      scrollButtons={isMobile ? 'auto' : 'off'}
      allowScrollButtonsMobile={isMobile}
      value={value}
      sx={tabsSx}
      variant={isMobile ? 'scrollable' : 'standard'}
      centered={!isMobile}
    >
      {children}
    </Tabs>
  );
}

export default React.memo(TabsHead);
