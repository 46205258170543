import * as React from 'react';

import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

function Loadable(Component) {
  return function wrapped(props) {
    return (
      <React.Suspense fallback={<Loader />}>
        <Component {...props} />
      </React.Suspense>
    );
  };
}

export default Loadable;
