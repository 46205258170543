import React from 'react';
import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';

import NewsSidebar from '../Sidebar/NewsSidebar';
import LeaguesSidebar from '../Sidebar/LeaguesSidebar';

export default function ScoreLayout() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2}>
      {isMobile ? null : <LeaguesSidebar />}
      <Grid size={{ xs: 12, md: 5.5 }} container direction="column" spacing={2}>
        <Outlet />
      </Grid>
      {isMobile ? null : <NewsSidebar />}
    </Grid>
  );
}
