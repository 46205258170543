export const AccordionLeagueSx = {
  width: '100%',
  borderRadius: '15px !important',
  '&::before': {
    display: 'none',
  },
};

export const homeGameSx = (theme) => ({
  width: '100%',
  '&:not(:last-child):after': {
    background: theme.palette.divider,
    width: '100%',
    height: '1px',
    content: '""',
    bottom: '0',
    zIndex: '0',
    overflow: 'hidden',
    position: 'absolute',
  },
});

export const homeGameButtonSx = (theme, isActive) => ({
  width: '100%',
  dsiplay: 'flex',
  flexGrow: '1',
  px: 0,
  '&::before': {
    content: '""',
    borderWidth: '0px 0px 0px 5px',
    borderStyle: 'solid',
    borderColor: isActive ? theme.palette.warning.main : 'transparent',
    height: 24,
    borderRadius: '0 15px 15px 0',
  },
});
