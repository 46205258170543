import Fuse from 'fuse.js';
import * as React from 'react';
import propTypes from 'prop-types';
import { createSelector } from 'reselect';
import Loadable from '@/components/Loadable';
import { setSearch } from '@/store/mainSlice';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import { Paper, Divider, InputBase, IconButton } from '@mui/material';

const Competition = Loadable(React.lazy(() => import('../Competition')));
// ==============================|| MAIN LAYOUT ||============================== //

// Selector to extract the posts data from the API slice
const flatGames = [
  (state) => state.api.queries?.['getGames(undefined)']?.data,
  (state) => state.main.search,
];

const options = {
  threshold: 0.4,
  maxPatternLength: 32,
  includeScore: true,
  ignoreLocation: true,
  keys: ['n', 'v.fn', 'c.t.n', 'c.t.sd', 'c.t.ab'],
};

const selectFlatGames = createSelector(flatGames, (data, search) => {
  if (!search || !data?.length) {
    return [];
  }

  const fuse = new Fuse(data.flatMap((league) => league.gs) || [], options);
  const result = fuse
    .search(search)
    .filter(({ score }) => score < 0.02)
    .map(({ item }) => item);

  return result;
});

const searchTextSelector = createSelector([(state) => state.main], (main) => main.search);

const PaperSx = {
  p: '6px 12px',
  display: 'flex',
  alignItems: 'center',
};
const InputBaseSx = { ml: 1, flex: 1 };
const IconButtonSx = { p: '10px' };

function Searcher({ isInteractive }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  const inputRef = React.useRef();
  const initRef = React.useRef(true);

  const dispatch = useDispatch();

  const data = useSelector(selectFlatGames);
  const search = useSelector(searchTextSelector);

  React.useEffect(() => {
    if (q && !search && initRef.current) {
      dispatch(setSearch(q.toLowerCase()));
      initRef.current = false;
    } else if (!q && search) {
      setSearchParams({ q: search });
    }
  }, [dispatch, setSearchParams, q, search]);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const value = (formData.get('q') || '').toLowerCase();
    if (!value) {
      handleClose();
    } else {
      setSearchParams({ q: value });
      dispatch(setSearch(value));
    }
  }

  const handleClose = () => {
    inputRef.current.value = '';
    dispatch(setSearch(''));
    setSearchParams({});
  };

  return (
    <>
      <Paper component="form" onSubmit={handleSubmit} sx={PaperSx}>
        <InputBase
          sx={InputBaseSx}
          placeholder="Search Team"
          defaultValue={search}
          name="q"
          ref={inputRef}
          disabled={!isInteractive}
          aria-label="search team"
        />
        <IconButton
          type="submit"
          color="success"
          sx={IconButtonSx}
          aria-label="search"
          disabled={!isInteractive}
        >
          <Search />
        </IconButton>
        {!!search && (
          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="error" sx={{ p: '10px' }} aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </>
        )}
      </Paper>
      {data && search && (
        <Competition isSearch index={-1} league={`Search Result For : ${search}`} games={data} />
      )}
    </>
  );
}

Searcher.propTypes = {
  isInteractive: propTypes.bool,
};

export default React.memo(Searcher);
