import propTypes from 'prop-types';

export const IAthlete = propTypes.shape({
  dn: propTypes.string.isRequired,
  fn: propTypes.string,
  i: propTypes.string.isRequired,
  lsn: propTypes.string,
});
export const ISub = propTypes.shape({
  ath: IAthlete,
  jsy: propTypes.string,
});
export const IPlay = propTypes.shape({
  ast: propTypes.bool,
  cl: propTypes.shape({
    dv: propTypes.string,
  }),
  og: propTypes.bool,
  pen: propTypes.bool,
  rc: propTypes.bool,
  spl: propTypes.bool,
  sub: propTypes.bool,
  yc: propTypes.bool,
});
export const IPlayer = propTypes.shape({
  ath: IAthlete,
  jsy: propTypes.string,
  pls: propTypes.arrayOf(IPlay),
  sbI: propTypes.bool.isRequired,
  sbIf: ISub,
  sbO: propTypes.bool.isRequired,
  sbOf: ISub,
  srt: propTypes.bool.isRequired,
});
export const IRoster = propTypes.shape({
  frt: propTypes.string,
  ha: propTypes.string.isRequired,
  rst: propTypes.arrayOf(IPlayer),
  t: propTypes.object,
});
export const ILineup = propTypes.shape({
  rst: propTypes.arrayOf(IRoster),
});
