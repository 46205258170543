import React from 'react';

import { red, yellow } from '@mui/material/colors';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import Subbed from './Subbed';
import CardIcon from './CardIcon';
import { IPlayer } from '../_types';
import SportsSoccerIcon from './SportsSoccerIcon';

const Player = React.memo(({ pls, jsy, ath, sbOf, sbIf, getAthlete }) => {
  const playerAction = React.useMemo(() => {
    if (!pls || !pls.length) {
      return;
    }
    const m = pls
      ?.map(
        (play) =>
          (play.spl && !play.ast && <SportsSoccerIcon displayValue={play.cl.dv} />) ||
          (play.rc && <CardIcon color={red[500]} displayValue={play.cl.dv} />) ||
          (play.yc && <CardIcon color={yellow[500]} displayValue={play.cl.dv} />)
      )
      .filter(Boolean);
    return m.length ? m : undefined;
  }, [pls]);
  return (
    <ListItem secondaryAction={playerAction} key={jsy}>
      <ListItemIcon>{jsy}</ListItemIcon>
      <ListItemText
        primary={ath.lsn || ath.dn}
        secondary={
          (sbOf && <Subbed getAthlete={getAthlete} {...sbOf} />) ||
          (sbIf && <Subbed getAthlete={getAthlete} {...sbIf} />) ||
          undefined
        }
      />
    </ListItem>
  );
});

Player.prototype = IPlayer;

export default Player;
