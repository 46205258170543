import React from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';

export default function GoHome() {
  return (
    <IconButton to="/" component={Link}>
      <ArrowBack />
    </IconButton>
  );
}
