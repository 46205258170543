import * as React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import TabItem from '@/components/TabItem';
import TabsHead from '@/components/TabsHead';
import { gamesStates } from '@/utils/constants';
import { setGameFilter } from '@/store/mainSlice';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import AlarmIcon from '@mui/icons-material/Alarm';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const icons = [
  <CalendarMonthIcon key="calendar" color="primary" />,
  <WhatshotIcon key="shot" color="error" />,
  <AlarmIcon key="alarm" color="info" />,
  <EventAvailableIcon key="event" color="success" />,
  <NewspaperIcon key="news" color="warning" />,
];

export const GameStateTabItem = React.memo(({ index, state, onClick }) => {
  const icon = icons[index];
  const handleTabChange = React.useCallback(() => onClick(index), [index, onClick]);

  return <TabItem icon={icon} disableRipple onClick={handleTabChange} label={state} />;
});

GameStateTabItem.propTypes = {
  index: propTypes.number,
  onClick: propTypes.any,
  state: propTypes.string,
};

const gameFilterSelector = createSelector([(state) => state.main], (main) => main.gameFilter);

function GameStateSelector({ isInteractive }) {
  const filter = useSelector(gameFilterSelector);

  const dispatch = useDispatch();
  const tabItems = React.useMemo(
    () =>
      gamesStates.map((state, index) => (
        <GameStateTabItem
          index={index}
          state={state}
          key={state}
          onClick={(indx) => isInteractive && dispatch(setGameFilter(indx))}
        />
      )),
    [dispatch, isInteractive]
  );

  return (
    <Card>
      <TabsHead value={filter}>
        {tabItems}
        <TabItem icon={icons[4]} disableRipple to="/news" component={Link} label="News" />
      </TabsHead>
    </Card>
  );
}

GameStateSelector.propTypes = {
  isInteractive: propTypes.bool,
};

export default React.memo(GameStateSelector);
