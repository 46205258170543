import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';
import useLeague from '@/hooks/useLeague';

import { styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import CardHeader from '@mui/material/CardHeader';

export const LeagueHeader = styled(CardHeader)({ padding: '12px 0px' });

export const LeagueLogo = React.memo(({ logo, name, size = 32, ...rest }) => {
  const loader = React.useMemo(
    () => <Skeleton width={size} height={size} variant="circular" />,
    [size]
  );

  return (
    <Image
      {...rest}
      alt={`${name} Soccer Streams`}
      width={size}
      height={size}
      showLoading={loader}
      duration={500}
      src={`https://v1.streamly.win/api/img/football/league/${logo}?w=${size * 2}&h=${size * 2}`}
    />
  );
});

LeagueLogo.propTypes = {
  logo: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  size: propTypes.number,
};

function League({ subheader, title, logo, action, name }) {
  const { subtitle } = useLeague(logo);

  return (
    <LeagueHeader
      avatar={<LeagueLogo name={name} logo={logo} />}
      title={title}
      subheader={subtitle || subheader}
      action={action}
    />
  );
}

League.propTypes = {
  action: propTypes.any,
  logo: propTypes.string,
  name: propTypes.string.isRequired,
  subheader: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default React.memo(League);
