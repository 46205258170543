import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';
import ErrorIcon from '@/components/ErrorIcon';

import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export const TeamLogo = React.memo(({ src, alt, size = 80, isMobile }) => {
  const avatarSize = isMobile ? size / 2 : size;
  const imageUrl = `https://v1.streamly.win/api/img/soccer/${src}.png?w=${
    avatarSize * 2
  }&h=${avatarSize * 2}`;
  return (
    <Image
      width={avatarSize}
      height={avatarSize}
      src={imageUrl}
      errorIcon={<ErrorIcon size={avatarSize} />}
      showLoading={<Skeleton variant="circular" width={avatarSize} height={avatarSize} />}
      alt={`${alt} Soccer Streams`}
    />
  );
});

TeamLogo.propTypes = {
  alt: propTypes.string,
  isMobile: propTypes.bool,
  size: propTypes.string,
  src: propTypes.string,
};

export const TeamName = React.memo(({ name, isWinner, isMobile }) => (
  <Typography
    color={isWinner ? 'textPrimary' : 'textSecondary'}
    fontWeight={isWinner ? 'bold' : 'normal'}
    variant={isMobile ? 'subtitle1' : 'h6'}
    align="center"
    noWrap
  >
    {name}
  </Typography>
));

TeamName.propTypes = {
  isMobile: propTypes.bool,
  isWinner: propTypes.bool,
  name: propTypes.string,
};

function Team({ team, isWinner }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const teamName = team.t.sd;

  return (
    <Grid
      size={{ sm: 5, xs: 4 }}
      container
      wrap="nowrap"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <TeamLogo isMobile={isMobile} src={team.i} alt={team.t.sd} />
      <TeamName isWinner={isWinner} isMobile={isMobile} name={teamName} />
    </Grid>
  );
}

Team.propTypes = {
  isWinner: propTypes.bool,
  team: propTypes.object,
};

export default React.memo(Team);
