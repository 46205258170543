import ctry from './ctry.json';
import leagues from './lgs.json';

const lgs = new Map(leagues.map((item) => [item.slg, item]));
export const leaguesSlugDB = new Map(leagues.map((item) => [item.n.toLowerCase(), item.slg]));
export const getLeagueSlugByName = (name) => leaguesSlugDB.get(name.toLowerCase());
export const getLeagueBySlug = (slug) => lgs.get(slug);
export const getLeagueByName = (name) => lgs.get(getLeagueSlugByName(name));

export default { lgs, ctry };
