import * as React from 'react';
import propTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, List, Paper, ListSubheader } from '@mui/material';
import { AccordionLeagueSx } from '@/styles';

import Game from './Game';
import League from './League';

function Competition({ games, league, slug = 'default', shouldDelayRender = false }) {
  const [shouldRender, setShouldRender] = React.useState(!shouldDelayRender);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!shouldDelayRender) {
      return;
    }

    const renderRef = ref.current;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setShouldRender(() => {
          observer.unobserve(renderRef);
          return true;
        });
      }
    });

    if (renderRef) {
      observer.observe(renderRef);
    }

    return () => {
      if (renderRef) {
        observer.unobserve(renderRef);
      }
    };
  }, [shouldDelayRender]);

  if (shouldDelayRender && !shouldRender) {
    return (
      <Box
        ref={ref}
        sx={{
          height: 42 + (games.length + 1) * 72,
        }}
      />
    );
  }

  return (
    <List
      sx={AccordionLeagueSx}
      component={Paper}
      subheader={
        <ListSubheader component="div">
          <League
            title={
              <Link
                component={RouterLink}
                title={`${league} live soccer streams`}
                underline="hover"
                color="inherit"
                to={`/league/${slug}`}
              >
                {league}
              </Link>
            }
            name={league}
            logo={slug}
            subheader={games?.length === 1 ? '1 game' : `${games?.length} games`}
          />
        </ListSubheader>
      }
    >
      {games?.map((game) => (
        <Game
          key={league + game.i}
          gameId={game.i}
          status={game.s}
          name={game.n}
          slug={game.l}
          date={game.d}
          teams={game.c}
        />
      ))}
    </List>
  );
}

Competition.propTypes = {
  games: propTypes.arrayOf(propTypes.any),
  league: propTypes.string.isRequired,
  shouldDelayRender: propTypes.bool,
  slug: propTypes.string,
};

export default React.memo(Competition);
