import * as React from 'react';
import dayjs from '@/utils/dayJs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const SyledCard = styled(Card)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: (theme.vars || theme).palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderColor: `${(theme.vars || theme).palette.primaryDark[700]}!important`,
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
    borderColor: `${(theme.vars || theme).palette.primaryDark[700]}!important`,
  },
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

function Author({ authors, date }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">{authors.join(' • ')}</Typography>
      </Box>
      <Typography variant="caption">{date}</Typography>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function MainSec({ data }) {
  const cardData = data.map((item) => ({
    id: item.id,
    tag: item.tags.join(' • '),
    title: item.title,
    img: item.images?.large || item.images?.thumb,
    authors: [item.provider, item.author].filter(Boolean),
    date: dayjs(item.publish_time).fromNow(),
    description: item.content,
  }));
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info('You clicked the filter chip.');
  };

  return (
    <Grid container spacing={3} columns={12}>
      <Grid size={{ xs: 12, md: 6 }}>
        <SyledCard
          variant="outlined"
          onFocus={() => handleFocus(0)}
          onBlur={handleBlur}
          tabIndex={0}
          component={Link}
          to={`/news/${cardData[0].id}`}
          className={focusedCardIndex === 0 ? 'Mui-focused' : ''}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            image={cardData[0].img}
            sx={{
              aspectRatio: '16 / 9',
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          />
          <SyledCardContent>
            <Typography gutterBottom variant="caption" component="div">
              {cardData[0].tag}
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {cardData[0].title}
            </Typography>
            <StyledTypography variant="body2" color="text.secondary" gutterBottom>
              {cardData[0].description}
            </StyledTypography>
          </SyledCardContent>
          <Author authors={cardData[0].authors} date={cardData[0].date} />
        </SyledCard>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <SyledCard
          variant="outlined"
          onFocus={() => handleFocus(1)}
          onBlur={handleBlur}
          tabIndex={0}
          className={focusedCardIndex === 1 ? 'Mui-focused' : ''}
          component={Link}
          to={`/news/${cardData[1].id}`}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            image={cardData[1].img}
            aspect-ratio="16 / 9"
            sx={{
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          />
          <SyledCardContent>
            <Typography gutterBottom variant="caption" component="div">
              {cardData[1].tag}
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {cardData[1].title}
            </Typography>
            <StyledTypography variant="body2" color="text.secondary" gutterBottom>
              {cardData[1].description}
            </StyledTypography>
          </SyledCardContent>
          <Author authors={cardData[1].authors} date={cardData[1].date} />
        </SyledCard>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <SyledCard
          variant="outlined"
          onFocus={() => handleFocus(2)}
          onBlur={handleBlur}
          tabIndex={0}
          component={Link}
          to={`/news/${cardData[2].id}`}
          className={focusedCardIndex === 2 ? 'Mui-focused' : ''}
          sx={{ height: '100%' }}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            image={cardData[2].img}
            sx={{
              height: { sm: 'auto', md: '50%' },
              aspectRatio: { sm: '16 / 9', md: '' },
            }}
          />
          <SyledCardContent>
            <Typography gutterBottom variant="caption" component="div">
              {cardData[2].tag}
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {cardData[2].title}
            </Typography>
            <StyledTypography variant="body2" color="text.secondary" gutterBottom>
              {cardData[2].description}
            </StyledTypography>
          </SyledCardContent>
          <Author authors={cardData[2].authors} date={cardData[2].date} />
        </SyledCard>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
          }}
        >
          <SyledCard
            variant="outlined"
            onFocus={() => handleFocus(3)}
            component={Link}
            to={`/news/${cardData[3].id}`}
            onBlur={handleBlur}
            tabIndex={0}
            className={focusedCardIndex === 3 ? 'Mui-focused' : ''}
            sx={{ height: '100%' }}
          >
            <SyledCardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <div>
                <Typography gutterBottom variant="caption" component="div">
                  {cardData[3].tag}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {cardData[3].title}
                </Typography>
                <StyledTypography variant="body2" color="text.secondary" gutterBottom>
                  {cardData[3].description}
                </StyledTypography>
              </div>
            </SyledCardContent>
            <Author authors={cardData[3].authors} date={cardData[3].date} />
          </SyledCard>
          <SyledCard
            variant="outlined"
            onFocus={() => handleFocus(4)}
            component={Link}
            to={`/news/${cardData[4].id}`}
            onBlur={handleBlur}
            tabIndex={0}
            className={focusedCardIndex === 4 ? 'Mui-focused' : ''}
            sx={{ height: '100%' }}
          >
            <SyledCardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <div>
                <Typography gutterBottom variant="caption" component="div">
                  {cardData[4].tag}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {cardData[4].title}
                </Typography>
                <StyledTypography variant="body2" color="text.secondary" gutterBottom>
                  {cardData[4].description}
                </StyledTypography>
              </div>
            </SyledCardContent>
            <Author authors={cardData[4].authors} date={cardData[4].date} />
          </SyledCard>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <SyledCard
          variant="outlined"
          onFocus={() => handleFocus(5)}
          component={Link}
          to={`/news/${cardData[5].id}`}
          onBlur={handleBlur}
          tabIndex={0}
          className={focusedCardIndex === 5 ? 'Mui-focused' : ''}
          sx={{ height: '100%' }}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            image={cardData[5].img}
            sx={{
              height: { sm: 'auto', md: '50%' },
              aspectRatio: { sm: '16 / 9', md: '' },
            }}
          />
          <SyledCardContent>
            <Typography gutterBottom variant="caption" component="div">
              {cardData[5].tag}
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {cardData[5].title}
            </Typography>
            <StyledTypography variant="body2" color="text.secondary" gutterBottom>
              {cardData[5].description}
            </StyledTypography>
          </SyledCardContent>
          <Author authors={cardData[5].authors} date={cardData[5].date} />
        </SyledCard>
      </Grid>
    </Grid>
  );
}
