import React from 'react';
import Game from '@/views/Home/Competition/Game';

import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListSubheader from '@mui/material/ListSubheader';

import { getLeagueSlugByName } from '../../_data/DB';

function H2HGame({ game, homeTeam, awayTeam }) {
  const leagueSlug = getLeagueSlugByName(game.lgn);
  const teams = React.useMemo(
    () => [
      { ...homeTeam, sc: game.hts },
      { ...awayTeam, sc: game.ats },
    ],
    [awayTeam, game.ats, game.hts, homeTeam]
  );
  return (
    <Game
      gameId={game.i}
      isH2H
      status={{
        dc: '',
        tp: {
          cm: true,
          st: 'post',
        },
      }}
      name={`${homeTeam.t.n} vs ${awayTeam.t.n}`}
      slug={leagueSlug}
      date={game.gd}
      teams={teams}
    />
  );
}

export default function H2H({ h2h, getTeam }) {
  return (
    <List component={Paper} subheader={<ListSubheader component="div">Head To Head</ListSubheader>}>
      {h2h.evs?.map((game) => (
        <H2HGame
          key={`h2h-games${game.i}`}
          homeTeam={getTeam(game.hti)}
          awayTeam={getTeam(game.ati)}
          game={game}
        />
      ))}
    </List>
  );
}
