import * as React from 'react';
import propTypes from 'prop-types';
import GoHome from '@/utils/GoHome';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function Page({ avatar, title, subheader, children }) {
  return (
    <Card>
      <CardHeader avatar={avatar} title={title} subheader={subheader} action={<GoHome />} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

Page.propTypes = {
  avatar: propTypes.node,
  children: propTypes.node,
  subheader: propTypes.string,
  title: propTypes.string,
};
