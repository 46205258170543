import * as React from 'react';
import dayjs from '@/utils/dayJs';

import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';

const dateFormater = (date) => {
  const parsedDate = dayjs(date, 'YYYYMMDD');
  const prefix = parsedDate.isToday() ? 'Today' : parsedDate.format('ddd');
  return [prefix, parsedDate.format('D MMM')];
};

const FormatDateButton = React.memo(({ item, shouldFormat, formater, ...rest }) => (
  <DayButton {...rest}>
    {shouldFormat ? (
      formater(item).map((s, index) => (
        <Typography key={s} variant={!index ? 'subtitle2' : 'caption'}>
          {s}
        </Typography>
      ))
    ) : (
      <Typography>{item}</Typography>
    )}
  </DayButton>
));

const DayButton = styled(Button)(({ theme, isActive }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  // borderTopLeftRadius: 8,
  // borderTopRightRadius: 8,
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
  // borderBottom: isActive
  //   ? `3px solid ${theme.palette.primary.main}`
  //   : `1px solid ${theme.palette.divider}`,
}));

const PillsSx = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

function PillsSelector({ data, active, setActive, formater = dateFormater, shouldFormat = false }) {
  return (
    <ButtonGroup sx={PillsSx} variant="outlined" aria-label="Basic button group">
      {data?.map((item, index) => (
        <FormatDateButton
          key={`item${index}${2}`}
          onClick={() => setActive(index)}
          isActive={active === index}
          item={item}
          shouldFormat={shouldFormat}
          formater={formater}
        />
      ))}
    </ButtonGroup>
  );
}

export default React.memo(PillsSelector);
