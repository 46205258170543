import Head from '@/components/Head';
import React, { useState } from 'react';
import Page from '@/layout/MainLayout/Page';
import FormStyled from '@/components/FormStyled';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ContactMailRounded from '@mui/icons-material/ContactMailRounded';

export function Component() {
  const [status, setStatus] = useState('');
  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStatus('');
  };

  return (
    <Page avatar={<ContactMailRounded />} title="Get In Touch" subheader="Advertise">
      <Head title="Advertise - Soccer streams 100" description="Advertise with Streams 100" />
      <FormStyled
        autoComplete="off"
        onSubmit={submitForm}
        action="https://formspree.io/f/mvolyqdl"
        method="POST"
      >
        <TextField
          type="text"
          name="name"
          label="Full Name"
          required
          variant="outlined"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="email"
          name="email"
          label="Email"
          required
          variant="outlined"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="url"
          name="url"
          label="Link"
          required
          variant="outlined"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="text"
          name="message"
          label="Message"
          required
          variant="outlined"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          multiline
          rows={5}
        />
        <Typography variant="body1" align="right" style={{ margin: 8, width: '100%' }}>
          <Button variant="contained" type="submit">
            Send
          </Button>
        </Typography>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={status === 'SUCCESS'}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert elevation={6} variant="filled" severity="success">
            Thanks! Message successfully Sent . We will reach you soon
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={status === 'ERROR'}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert elevation={6} variant="filled" severity="error">
            Oops ! There was an error
          </Alert>
        </Snackbar>
      </FormStyled>
    </Page>
  );
}
