import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useLeague from '@/hooks/useLeague';
import { topLeagues } from '@/utils/constants';

import Grid from '@mui/material/Grid2';
import {
  List,
  Paper,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material';

const leagues = Object.entries(topLeagues);

const LeagueItemAvatar = React.memo(({ slug, name, size = 40 }) => {
  const { ctry } = useLeague(slug);

  return (
    <ListItem component="div" key={name} disableGutters disablePadding>
      <ListItemButton disableRipple component={Link} to={`league/${slug.replace('.', '-')}`}>
        <ListItemIcon>
          <Image
            width={size}
            height={size}
            showLoading={<Skeleton variant="circular" width={size} height={size} />}
            alt={name}
            src={`/img/leagues/120/${slug}.png`}
          />
        </ListItemIcon>
        <ListItemText primary={name} secondary={ctry} />
      </ListItemButton>
    </ListItem>
  );
});

LeagueItemAvatar.propTypes = {
  slug: propTypes.string,
  name: propTypes.string,
  size: propTypes.number,
};

function LeaguesSidebar() {
  return (
    <Grid size={{ xs: 12, md: 3 }}>
      <List
        component={Paper}
        subheader={<ListSubheader component="div">Top Leagues</ListSubheader>}
      >
        {leagues.map(([slug, name]) => (
          <LeagueItemAvatar key={name} slug={slug} name={name} />
        ))}
      </List>
    </Grid>
  );
}

export default React.memo(LeaguesSidebar);
