import * as React from 'react';
import dayjs from '@/utils/dayJs';
import { Link } from 'react-router-dom';
import Head from '@/components/Head.js';
import Spinner from '@/components/Spinner';
import { usePostsQuery } from '@/store/apiSlice';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import MainSection from './MainSection';

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  textDecoration: 'none',
  '&:hover': { cursor: 'pointer' },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '3px',
    borderRadius: '8px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: '1px',
    bottom: 0,
    left: 0,
    backgroundColor: (theme.vars || theme).palette.text.primary,
    opacity: 0.3,
    transition: 'width 0.3s ease, opacity 0.3s ease',
  },
  '&:hover::before': {
    width: '100%',
  },
}));

function Author({ authors, date }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">{authors.filter(Boolean).join(' • ')}</Typography>
      </Box>
      <Typography variant="caption">{dayjs(date).fromNow()}</Typography>
    </Box>
  );
}

export function Component() {
  const { data, isFetching, isSuccess } = usePostsQuery();
  const posts = data || []; // .slice().sort((a, b) => b.id - a.id);

  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);
  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  if (isFetching) return <Spinner />;

  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
    >
      <Head
        title="Soccer News | Soccer-100.com"
        description="Watch live soccer streams, get real-time scores, match statistics, and detailed data on soccer-100.com. Stay updated with the latest soccer action, results, and insights"
      />
      <div>
        <Typography component="h1" variant="h3" gutterBottom>
          News
        </Typography>
        <Typography>Stay in the loop with the latest news</Typography>
      </div>
      <MainSection data={posts.slice(0, 6)} />
      <Typography component="h2" variant="h3">
        Latest
      </Typography>
      <Grid container spacing={8} columns={12}>
        {posts.slice(6, data.length).map((article, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 1,
                height: '100%',
              }}
            >
              <Typography gutterBottom variant="caption" component="div">
                {article.tags.join(' • ')}
              </Typography>
              <TitleTypography
                gutterBottom
                variant="h6"
                onFocus={() => handleFocus(index)}
                onBlur={handleBlur}
                component={Link}
                to={`/news/${article.id}`}
                tabIndex={0}
                color="text.primary"
                className={focusedCardIndex === index ? 'Mui-focused' : ''}
              >
                {article.title}
              </TitleTypography>
              <StyledTypography variant="body2" color="text.secondary" gutterBottom>
                {article.content}
              </StyledTypography>

              <Author authors={[article.provider, article.author]} date={article.publish_time} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
