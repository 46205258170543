import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TabItem from '@/components/TabItem';
import TabsHead from '@/components/TabsHead';

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';

import Team from './Team';
import Time from './Time';

// const PubStream = Loadable(React.lazy(() => import('../../../ui-component/PubStream')));

const MatchHeader = React.memo(({ v, slug, a, h, d, st, href, tab, activeTabs, isLoading }) => (
  <Paper>
    <Grid container alignItems="center" justifyContent="center" component={CardContent}>
      {isLoading ? <TeamSkeleton /> : <Team isWinner={h.sc > a.sc} team={h} />}
      {isLoading ? (
        <TimeSkeleton />
      ) : (
        <Time date={d} status={st.tp || st} homeScore={h.sc} awayScore={a.sc} />
      )}
      {isLoading ? <TeamSkeleton /> : <Team isWinner={h.sc < a.sc} team={a} />}
    </Grid>
    <Divider />
    {isLoading ? (
      <TabsSkeleton />
    ) : (
      <TabsHead value={tab}>
        <TabItem disableRipple to={`${href}`} component={Link} label="Info" />
        {activeTabs.includes('timeline') && (
          <TabItem disableRipple to={`${href}/timeline`} component={Link} label="Timeline" />
        )}
        {activeTabs.includes('stats') && (
          <TabItem disableRipple to={`${href}/stats`} component={Link} label="Stats" />
        )}
        {activeTabs.includes('table') && (
          <TabItem disableRipple to={`${href}/table`} component={Link} label="Table" />
        )}
        {activeTabs.includes('squad') && (
          <TabItem disableRipple to={`${href}/squad`} component={Link} label="Squad" />
        )}
      </TabsHead>
    )}
  </Paper>
));

function TeamSkeleton() {
  return (
    <Grid
      size={{ sm: 5, xs: 4 }}
      container
      wrap="nowrap"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Skeleton variant="circular" sx={{ width: 80, height: 80 }} />
      <Skeleton height={32} width="35%" sx={{ m: 'auto' }} />
    </Grid>
  );
}

const StyledSkeletonTabs = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const TabSkeletonItem = styled(Skeleton)({
  width: 50,
  height: 48,
  margin: '0px 16px',
});

export function TabsSkeleton({ count = 4 }) {
  return (
    <StyledSkeletonTabs>
      {Array.from({ length: count }).map((_, index) => (
        <TabSkeletonItem key={`tabSkeletion${index}`} />
      ))}
    </StyledSkeletonTabs>
  );
}

TabsSkeleton.propTypes = {
  count: propTypes.number,
};

function TimeSkeleton() {
  return (
    <Grid size={{ xs: 2 }}>
      <Skeleton height={25} width="50%" sx={{ m: 'auto' }} />
    </Grid>
  );
}

export default MatchHeader;
