import * as React from 'react';
import Head from '@/components/Head.js';
import { useParams } from 'react-router';
import Spinner from '@/components/Spinner';
import { Link as LinkDom } from 'react-router-dom';
import { usePostByIdQuery } from '@/store/apiSlice';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Divider } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

// Replicate the value used by https://medium.com/, a trusted reference.
const BLOG_MAX_WIDTH = 692;

const classes = {
  back: 'TopLayoutBlog-back',
  time: 'TopLayoutBlog-time',
  container: 'TopLayoutBlog-container',
};

const AuthorsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
  '& .author': {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    '& .MuiAvatar-root': {
      marginRight: theme.spacing(1),
    },
  },
}));

const Root = styled('div')(
  ({ theme }) => ({
    flexGrow: 1,
    backgroundSize: '100% 500px',
    backgroundRepeat: 'no-repeat',
    [classes.back]: {
      textDecoration: 'none',
    },
    [`& .${classes.back}`]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(-1),
    },
    [`& .${classes.container}`]: {
      maxWidth: `calc(${BLOG_MAX_WIDTH}px + ${theme.spacing(2 * 2)})`,
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(${BLOG_MAX_WIDTH}px + ${theme.spacing(3 * 2)})`,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: `calc(${BLOG_MAX_WIDTH}px + ${theme.spacing(8 * 2)})`,
      },
      '& h1': {
        marginBottom: theme.spacing(3),
      },
    },
    '& .markdown-body': {
      lineHeight: 1.7,
      ' & h1, & h2, & h3, & h4': {
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        gap: '6px',
        margin: '40px 0px 4px',
      },
      '& img, & video': {
        border: '1px solid',
        borderColor: (theme.vars || theme).palette.grey[200],
        borderRadius: 12,
        display: 'block',
        margin: 'auto',
        marginBottom: 16,
      },
      '& figure': {
        margin: 0,
        padding: 0,
        marginBottom: 16,
        '& img, & video': {
          marginBottom: 8,
        },
      },
      '& figcaption': {
        color: (theme.vars || theme).palette.text.tertiary,
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
      },
      '& strong': {
        color: (theme.vars || theme).palette.grey[900],
      },
      '& summary': {
        padding: 8,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
        color: (theme.vars || theme).palette.grey[900],
      },
      '& details': {
        paddingLeft: 16,
        paddingRight: 16,
        background: alpha(theme.palette.grey[50], 0.5),
        border: '1px solid',
        borderRadius: 10,
        borderColor: (theme.vars || theme).palette.grey[200],
        transitionProperty: 'all',
        transitionTiming: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '200ms',
        '&:hover, &:focus-visible': {
          background: (theme.vars || theme).palette.grey[50],
          borderColor: (theme.vars || theme).palette.grey[300],
        },
      },
      '& th': {
        width: '100%',
        textAlign: 'left',
        borderBottom: `3px solid rgba(62, 80, 96, 0.2) !important`,
      },
      '& .blog-description': {
        fontSize: theme.typography.pxToRem(13),
        marginTop: 8,
        textAlign: 'center',
        color: (theme.vars || theme).palette.grey[700],
        '& a': {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },
      '& .MuiCode-root + .blog-description': {
        marginTop: -20 + 8,
      },
    },
    [`& .${classes.time}`]: {
      color: (theme.vars || theme).palette.text.secondary,
      ...theme.typography.caption,
      fontWeight: 500,
    },
  }),
  ({ theme }) =>
    theme.applyDarkStyles({
      backgroundSize: '100% 1000px',
      backgroundRepeat: 'no-repeat',
      '& .markdown-body': {
        '& strong': {
          color: (theme.vars || theme).palette.grey[100],
        },
        '& summary': {
          color: (theme.vars || theme).palette.grey[300],
        },
        '& img, & video': {
          borderColor: alpha(theme.palette.primaryDark[600], 0.5),
        },
        '& details': {
          background: alpha(theme.palette.primary[900], 0.3),
          borderColor: (theme.vars || theme).palette.primaryDark[700],
          '&:hover, &:focus-visible': {
            background: alpha(theme.palette.primary[900], 0.4),
            borderColor: (theme.vars || theme).palette.primaryDark[500],
          },
        },
        '& .blog-description': {
          color: (theme.vars || theme).palette.grey[500],
        },
      },
    })
);

export function Component(props) {
  const { id } = useParams();
  const { data, isFetching, isSuccess } = usePostByIdQuery(id);

  if (isFetching) {
    return <Spinner />;
  }

  const renderers = {
    paragraph: (content) => (
      <Typography my={1} variant="subtitle1">
        {content}
      </Typography>
    ),
    horizontal_separator: () => <Divider my={1} />,
    section_title: (content) => (
      <h2 my={2} component="h2" variant="h4">
        {content}
      </h2>
    ),
  };

  return (
    <Root>
      <Head
        title={`${data?.title} | Soccer-100.com`}
        description="Watch live soccer streams, get real-time scores, match statistics, and detailed data on soccer-100.com. Stay updated with the latest soccer action, results, and insights"
      />
      <Container component="main" className={classes.container}>
        <Link
          component={LinkDom}
          to="/news"
          variant="body2"
          className={classes.back}
          sx={{ color: 'primary', textDecoration: 'none' }}
        >
          <ChevronLeftRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />
          Back to News
        </Link>
        {data?.title ? (
          <>
            <time dateTime={data?.publish_date} className={classes.time}>
              {new Intl.DateTimeFormat('en', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }).format(new Date(data?.publish_time))}
            </time>
            <h1>{data.title}</h1>
          </>
        ) : null}
        <Box className="markdown-body">
          {(data?.images?.large || data?.images?.thumb) && (
            <Box
              component="img"
              src={data.images?.large || data.images?.thumb}
              alt={data.title}
              width="1280"
              height="640"
            />
          )}
          {data?.parts?.map((chunk) => renderers[chunk.type]?.(chunk.content))}
        </Box>
      </Container>
    </Root>
  );
}
