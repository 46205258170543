import store from '@/store';
import * as React from 'react';
import Head from '@/components/Head';
import Spinner from '@/components/Spinner';
import { api, useGetGamesQuery } from '@/store/apiSlice';

// import LeaguesList from "./LeaguesList";
import Search from './Search';
import TopGames from './TopGames';
import GamesList from './GamesList';
import GameStateSelector from './GameStateSelector';

// const Competition = Loadable(React.lazy(() => import("./Competition")));

// Selector to extract the posts data from the API slice

// ==============================|| MAIN LAYOUT ||============================== //

export async function loader() {
  store.dispatch(api.endpoints.getGames.initiate());
  return null;
}

export function Component() {
  const { isFetching, isSuccess } = useGetGamesQuery();
  return (
    <>
      <Head
        title="Soccer Streams, Scores, Stats & Data - Real-Time Updates | Soccer-100.com"
        description="Watch live soccer streams, get real-time scores, match statistics, and detailed data on soccer-100.com. Stay updated with the latest soccer action, results, and insights"
      />
      <GameStateSelector isInteractive={isSuccess} />
      <Search isInteractive={isSuccess} />

      {isFetching && <Spinner />}
      {isSuccess && <TopGames />}
      {isSuccess && <GamesList />}
    </>
  );
}
