import * as React from 'react';

export default function Head(props) {
  const { description, title } = props;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta
        content="soccer streams, live soccer scores, soccer match statistics, soccer data,
            live soccer updates, football scores, real-time soccer stats,soccer live feed, soccer 100"
        name="keywords"
      />
    </>
  );
}
