import React from 'react';

import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme, isHome, val }) => ({
  display: 'flex',
  width: `calc(${val}% - 4px)`,
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.divider,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: isHome ? theme.palette.success.main : theme.palette.info.main,
  },
}));

const percentage = (x, y) => {
  const xOrZero = x <= 0 ? 0 : x;
  const yOrZero = y <= 0 ? 0 : y;
  if (xOrZero === 0 && yOrZero === 0) {
    return 50;
  }
  if (xOrZero === 0) {
    return 0;
  }
  return ((x / (x + yOrZero)) * 100).toFixed(2);
};

function TeamProgressRow({ homeTeamVal, awayTeamVal, label }) {
  const homeVal = Number.parseFloat(homeTeamVal);
  const awayVal = Number.parseFloat(awayTeamVal);
  return (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid container justifyContent="space-between">
        <Typography
          variant="h6"
          component="span"
          color="textSecondary"
          sx={{ flex: 1, textAlign: 'left' }}
        >
          {homeVal < 1 && homeVal > 0 ? homeVal * 100 : homeTeamVal}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" sx={{ flex: 1, textAlign: 'center' }}>
          {label}
        </Typography>
        <Typography
          variant="h6"
          component="span"
          color="textSecondary"
          sx={{ flex: 1, textAlign: 'right' }}
        >
          {awayVal < 1 && awayVal > 0 ? awayVal * 100 : awayTeamVal}
        </Typography>
      </Grid>

      <Grid container justifyContent="space-between" alignItems="center" gap={1}>
        {homeVal >= 0 && percentage(awayVal, homeVal) < 100 && (
          <BorderLinearProgress
            isHome
            variant="determinate"
            value={100}
            val={percentage(homeVal, awayVal) ?? 0}
          />
        )}
        {awayVal >= 0 && percentage(homeVal, awayVal) < 100 && (
          <BorderLinearProgress
            variant="determinate"
            value={100}
            val={percentage(awayVal, homeVal) ?? 0}
          />
        )}
      </Grid>
    </Grid>
  );
}

const MatchStats = React.memo(({ ts }) => {
  const [home, away] = ts;

  const [show, setShow] = React.useState(false);

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid container direction="column" wrap="nowrap" gap={2} p={2}>
        {home?.stsc?.map(
          (sts, i) =>
            ((i > 5 && show) || i < 5) && (
              <TeamProgressRow
                awayTeamVal={away.stsc[i]?.dv}
                homeTeamVal={home.stsc[i]?.dv}
                label={sts.lbl}
                key={sts.lbl}
              />
            )
        )}
      </Grid>
      {home?.stsc.length > 5 && (
        <Typography
          sx={{
            '&:hover': { cursor: 'pointer' },
            p: 1,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            gap: 1,
            alignItems: 'center',
          }}
          onClick={() => setShow(!show)}
        >
          {show ? 'Hide Stats' : 'Show All Stats'}
          <IconButton aria-label="expand row" size="small">
            {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Typography>
      )}
    </Paper>
  );
});

export default MatchStats;
