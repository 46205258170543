import { configureStore } from '@reduxjs/toolkit';

import { api } from './apiSlice';
import * as mainSlice from './mainSlice';

export default configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    main: mainSlice.default,
  },
  // Add the RTK Query API middleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});
