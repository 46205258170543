import * as React from 'react';
import TabsHead from '@/components/TabsHead';
import { useLocation } from 'react-router-dom';
import { headerMenu } from '@/utils/constants';

import MenuItem from './MenuItem';

const headerMenuList = Object.entries(headerMenu);
const headerMenuListKeys = Object.keys(headerMenu);

function Menu() {
  const { pathname } = useLocation();
  const value = React.useMemo(() => headerMenuListKeys.indexOf(pathname), [pathname]);

  const renderItem = React.useCallback(
    ([to, { label, isExternal, disabled }]) =>
      disabled ? null : <MenuItem to={to} key={to} label={label} isExternal={isExternal} />,
    []
  );

  return (
    <TabsHead component="nav" value={value}>
      {headerMenuList.map(renderItem)}
    </TabsHead>
  );
}

export default React.memo(Menu);
