import * as React from 'react';
import dayjs from '@/utils/dayJs';

import DB from '../_data/DB';

const today = new Date();

const isTodayBetweenDates = (startDateString, endDateString) =>
  dayjs().isBetween(startDateString, endDateString);

function useLeague(leagueSlug) {
  const league = DB.lgs.get(leagueSlug);

  return React.useMemo(() => {
    if (!league) {
      return { name: 'League not found', subtitle: '' };
    }

    const subtitle =
      (league.ctry === 2 || league.isTrmt
        ? league.szTp?.find(({ sd, ed }) => isTodayBetweenDates(sd, ed))?.n ||
          league.szTp
            ?.filter(({ ed }) => new Date(ed) < today) // Filter seasons that ended in the past
            .sort((a, b) => new Date(b.ed) - new Date(a.ed)) // Sort by the most recent past season
            .shift()?.n
        : null) ||
      DB.ctry[league.ctry]?.n ||
      '';
    return { ...league, ctry: DB.ctry[league.ctry]?.n, subtitle };
  }, [league]);
}

export default useLeague;
