import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TabItem from '@/components/TabItem';

import MuiLink from '@mui/material/Link';

function MenuItem({ to, label, isExternal }) {
  const component = isExternal ? MuiLink : Link;
  return (
    <TabItem
      disableRipple
      label={label}
      component={component}
      href={isExternal ? to : undefined}
      to={isExternal ? undefined : to}
    />
  );
}

MenuItem.propTypes = {
  isExternal: propTypes.bool,
  label: propTypes.string,
  to: propTypes.string,
};
export default React.memo(MenuItem);
