export const filterLeague = (league, filter) => {
  switch (filter) {
    case 0:
      return true;
    case 1:
      return league.gs.some((game) => game.s.tp.st === 'in'); // Live Games
    case 2:
      return league.gs.some((game) => game.s.tp.st === 'pre'); // Upcoming Games
    case 3:
      return league.gs.some((game) => game.s.tp.cm); // Finished Games
    default:
      return true;
  }
};

export const filterGame = (game, filter) => {
  switch (filter) {
    case 0:
      return true; // Show all games if filter is 0
    case 1:
      return game.s.tp.st === 'in'; // Live Games
    case 2:
      return game.s.tp.st === 'pre'; // Upcoming Games
    case 3:
      return game.s.tp.cm; // Finished Games
    default:
      return true; // Default case to avoid unexpected behavior
  }
};
