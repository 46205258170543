import * as React from 'react';

import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export default function Spinner(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
      }}
      my={4}
    >
      <Box>
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: '#1a1a1a',
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: '#111111',
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={40}
            thickness={4}
            {...props}
          />
        </Box>
      </Box>
    </Box>
  );
}
