import React from 'react';
import dayjs from '@/utils/dayJs';
import propTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Score({ status, homeScore, awayScore, date }) {
  const isPlayingOrFinished = React.useMemo(
    () => status.tp.st === 'in' || status.tp.cm || status.tp.n === 'STATUS_POSTPONED',
    [status.tp.cm, status.tp.n, status.tp.st]
  );

  const formattedDate = React.useMemo(() => {
    if (!(status.tp.st === 'pre')) {
      return null;
    }
    return (
      <Typography color="textSecondary" align="center" component="div" variant="body2" noWrap>
        {dayjs(date).format('HH:mm')}
      </Typography>
    );
  }, [date, status.tp.st]);

  const score = React.useMemo(() => {
    if (!isPlayingOrFinished) {
      return null;
    }
    return [homeScore, awayScore].map((sc, index) => {
      const isWinner =
        (index === 0 && homeScore > awayScore) || (index === 1 && awayScore > homeScore);
      return (
        <Typography
          key={`team${index + 1}`}
          color={isWinner ? 'textPrimary' : 'textSecondary'}
          fontWeight={isWinner ? 'bold' : 'normal'}
          variant="body1"
          align="center"
          noWrap
        >
          {sc}
        </Typography>
      );
    });
  }, [isPlayingOrFinished, homeScore, awayScore]);

  return (
    <Grid xs={2} item container gap={1} direction="column" alignItems="center">
      {score}
      {formattedDate}
    </Grid>
  );
}

Score.propTypes = {
  awayScore: propTypes.string.isRequired,
  date: propTypes.string.isRequired,
  homeScore: propTypes.string.isRequired,
  status: propTypes.shape({
    cl: propTypes.string.isRequired,
    dc: propTypes.string.isRequired,
    p: propTypes.string.isRequired,
    tp: {
      cm: propTypes.bool.isRequired,
      st: propTypes.string.isRequired,
      n: propTypes.string.isRequired,
    },
  }),
};

export default React.memo(Score);
