import store from '@/store';
import * as React from 'react';
import dayjs from '@/utils/dayJs';
import Head from '@/components/Head';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import TabItem from '@/components/TabItem';
import TabsHead from '@/components/TabsHead';
import LeagueTable from '@/views/Match/LeagueTable';
import GamesListCard from '@/views/Home/GamesListCard';
import { TabsSkeleton } from '@/views/Match/MatchHeader';
import { api, useLeagueByIdQuery } from '@/store/apiSlice';
import { LeagueLogo, LeagueHeader } from '@/views/Home/Competition/League';

import { Card, Divider, Skeleton, Typography } from '@mui/material';

import DB from '../../_data/DB';
import PillsSelector from './PillsSelector';

function convertToReadableDate(dateString) {
  return dayjs(dateString, 'YYYYMMDD').format('ddd, D MMM YYYY');
}

export async function loader({ params }) {
  store.dispatch(api.endpoints.leagueById.initiate(params.slug.replaceAll('-', '.')));
  return null;
}

export function Component() {
  const { slug, tab = 'matches' } = useParams();
  const href = `/league/${slug}`;
  const leagueSlug = slug.replaceAll('-', '.');
  const league = DB.lgs.get(leagueSlug);

  const { data, isFetching, isSuccess } = useLeagueByIdQuery(leagueSlug);

  const actvDates = data?.cldr?.actv || [];

  const [activeDateIndex, setActiveDateIndex] = React.useState(1);

  React.useEffect(() => {
    if (!slug) {
      return;
    }
    setActiveDateIndex(1);
  }, [slug]);

  const activeTabs = React.useMemo(
    () =>
      [
        data?.evts && !!Object.keys(data.evts).length && 'matches',
        !!data?.stg?.length && 'table',
        // !!data?.tms?.length && "teams",
      ].filter((t) => t),
    [data]
  );

  const activeTab = React.useMemo(
    () => (activeTabs.includes(tab) ? tab : activeTabs[0]),
    [activeTabs, tab]
  );

  const activeDate = React.useMemo(() => actvDates[activeDateIndex], [activeDateIndex, actvDates]);

  return (
    <>
      {league && (
        <>
          <Head
            title={`${league.n} - Soccer Streams`}
            description={`Watch ${league.n} Soccer streams for free on Reddit Soccer Streams 100.Its the best alternative for Reddit Soccer Streams`}
          />
          <link rel="canonical" href={window.location} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png"
          />
          <meta property="og:title" content="Soccer Streams - Watch HD Soccer Streams For Free" />
          <meta
            property="og:description"
            content={`Watch ${league.n} Soccer streams for free on Reddit Soccer Streams .Its the best alternative for Reddit Soccer Streams`}
          />
          <meta property="og:url" content={window.location} />
          <meta property="og:site_name" content="Soccer Streams" />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      )}

      <Card>
        <LeagueHeader
          avatar={
            <LeagueLogo
              key={slug}
              isLoading={isFetching}
              wrapperStyle={{ marginLeft: 16, marginRight: 16 }}
              logo={leagueSlug}
              name={league?.n}
              size={88}
            />
          }
          title={
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="bold"
              textTransform="capitalize"
              component="h1"
            >
              {isFetching ? <Skeleton width={180} /> : league?.n}
            </Typography>
          }
          subheader={
            <Typography color="inherit" variant="subtitle2">
              {isFetching ? <Skeleton width={80} /> : data?.ctry?.n}
            </Typography>
          }
        />
        <Divider />
        {isFetching ? (
          <TabsSkeleton count={2} />
        ) : (
          <TabsHead value={activeTabs.indexOf(activeTab)}>
            {activeTabs.includes('matches') && (
              <TabItem disableRipple to={href} component={Link} label="Matches" />
            )}
            {activeTabs.includes('table') && (
              <TabItem disableRipple to={`${href}/table`} component={Link} label="Table" />
            )}
            {activeTabs.includes('teams') && (
              <TabItem disableRipple to={`${href}/teams`} component={Link} label="Teams" />
            )}
          </TabsHead>
        )}
      </Card>

      {tab === 'matches' && (
        <>
          {isSuccess && !isFetching && actvDates && (
            <PillsSelector
              active={activeDateIndex}
              setActive={setActiveDateIndex}
              shouldFormat
              data={actvDates}
            />
          )}
          {
            isSuccess && !isFetching && league && activeDate && data.evts && (
              // Object.entries(data.evts).map(([key, games]) => (
              <GamesListCard
                league={data.lg.slg}
                games={data.evts[activeDate]}
                title={convertToReadableDate(activeDate)}
              />
            )
            // ))
          }
        </>
      )}
      {isSuccess &&
        !isFetching &&
        data.stg &&
        (tab === 'table' || activeTabs.indexOf('table') === 0) && <LeagueTable data={data.stg} />}
    </>
  );
}
