import * as React from 'react';
import Head from '@/components/Head';
import Page from '@/layout/MainLayout/Page';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import LockRounded from '@mui/icons-material/LockRounded';

export function Component() {
  return (
    <Page avatar={<LockRounded />} title="Rules" subheader="Rules">
      <Head title="Rules - Soccerstreams100" description="Get In Touch with Streams100" />
      <Typography variant="body2" gutterBottom>
        Welcome to Soccer Streams! Below are a few rules that you must follow if you want to publish
        your streams with us. Please be aware that any stream submitter caught intentionally
        violating the rules will be subject to an immediate ban and blacklist from this website.
      </Typography>
      <Typography variant="body2" gutterBottom>
        <List>
          <ListItem>1. Link directly to the stream.</ListItem>
          <ListItem>2. Do not post links to websites with a list of different streams.</ListItem>
          <ListItem>
            3. If you are a streamer, please register your username based on your website’s name.
          </ListItem>
          <ListItem>
            4. You are not allowed to create duplicate accounts. Anyone found to have created
            duplicate accounts will be immediately banned and their domain will be blacklisted. We
            have a zero tolerance policy with this rule.
          </ListItem>
          <ListItem>
            5. Do not post fake comments to your stream links. You will be banned without notice and
            your domain will be blacklisted.
          </ListItem>
          <ListItem>6. Puffin browser does not count as mobile compatibility.</ListItem>
          <ListItem>
            7. We do not allow duplicate streams, meaning even if you have permission to embed
            someone else’s stream, if the original stream is already available in the Match Thread,
            your post will be removed.
          </ListItem>
          <ListItem>
            8. Advertising private social media accounts in streams or comments is not allowed.
          </ListItem>
          <ListItem>
            9. You must list the number of ad overlays on your stream, i.e. list the number of
            elements required to be closed before going to full-screen.
          </ListItem>
          <ListItem>
            10. If you caught mislabeling your stream, it will be removed and you will have to
            resubmit it. This will result in a loss in traffic for your streams, so it is your
            responsibility to accurately label your streams.
          </ListItem>
        </List>
      </Typography>
    </Page>
  );
}
