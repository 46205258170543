import { useParams } from 'react-router';
import { Link as Linkr } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Home from '@mui/icons-material/Home';
import { Box, Card, Link, CardHeader, Typography, Breadcrumbs } from '@mui/material';

import Competition from '../Home/Competition';

function SitemapLink() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`https://soccerstreams.app/api/sport/soccer/sitemap/${id}`, {
      method: 'GET',
    }).then(async (response) => {
      const res = await response.json();
      if (response.status === 200) {
        setData(res);
      }
    });
  }, [id]);

  return (
    <Box my={4}>
      <title>Sitemap - Soccer Streams</title>
      <meta
        name="description"
        content="You can watch Top Leagues live stream online for free at Reddit Soccer Streams. Watch HD Soccer streams for free on Soccer Streams."
      />
      <meta property="og:title" content="Top Leagues - Soccer Streams" />
      <meta
        property="og:description"
        content="You can watch Top Leagues live stream online for free at Reddit Soccer Streams.Watch HD Soccer streams for free on Soccer Streams."
      />
      <link rel="canonical" href={window.location} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png"
      />
      <meta property="og:url" content={window.location} />
      <meta property="og:site_name" content="Soccer Streams" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="soccerstreams100" />
      <meta name="twitter:label2" content="Est. reading time" />
      <meta name="twitter:data2" content="1 minute" />

      <Card>
        <CardHeader
          avatar={<Home />}
          title={
            <Breadcrumbs
              itemScope
              itemType="https://schema.org/BreadcrumbList"
              aria-label="breadcrumb"
            >
              <Link
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                component={Linkr}
                title="Soccer Streams"
                sx={{ display: 'flex', alignItems: 'center' }}
                underline="hover"
                color="inherit"
                to="/"
              >
                <meta itemProp="item" content="https://new.soccerstreams-100.tv/" />
                <meta itemProp="position" content="1" />
                <meta itemProp="name" content="Soccer Streams" />
                Soccer Streams
              </Link>
              <Typography
                color="text.primary"
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <meta itemProp="item" content={window.location} />
                <meta itemProp="position" content="2" />
                <meta itemProp="name" content="Sitemap" />
                Soccer Streams
              </Typography>
            </Breadcrumbs>
          }
        />
      </Card>

      {data?.map((league, index) => (
        <Competition
          key={league.lgn}
          index={index}
          league={league.lgn.toString()}
          games={league.gs}
        />
      ))}
    </Box>
  );
}

export default SitemapLink;
