import * as React from 'react';
import Head from '@/components/Head';
import Page from '@/layout/MainLayout/Page';

import Typography from '@mui/material/Typography';
import PolicyRounded from '@mui/icons-material/PolicyRounded';

export function Component() {
  return (
    <Page
      avatar={<PolicyRounded />}
      title="Privacy Policy"
      subheader="Soccerstreams100 Privacy Policy"
    >
      <Head title="Privacy - Soccerstreams100" description="Get In Touch with Streams100" />
      <Typography variant="body2" gutterBottom>
        This Privacy Policy governs the way in which nbastreams collects and uses information from
        Our users (each, a “User”) of the nbastreams site (“Site”).
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        1 – PERSONAL IDENTIFICATION INFORMATION :
      </Typography>
      <Typography variant="body2" gutterBottom>
        We may collect personal identification information from Users in a variety of ways in
        connection with activities, services, features or resources we make available on our Site.
        We will collect personal identification information from Users only if they voluntarily
        submit such information to us. Users can always refuse to supply personally identification
        information, except that it may prevent them from engaging in certain Site related
        activities.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        2 – NON-PERSONAL IDENTIFICATION INFORMATION :
      </Typography>
      <Typography variant="body2" gutterBottom>
        We may collect non-personal identification information about Users whenever they interact
        with our Site. Non-personal identification information may include the browser name, the
        type of computer and technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and other similar
        information.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        3 – ADVERTISING :
      </Typography>
      <Typography variant="body2" gutterBottom>
        Ads appearing on our site may be delivered to Users by advertising partners, who may set
        cookies. These cookies allow the ad server to recognize your computer each time they send
        you an online advertisement to compile non personal identification information about you or
        others who use your computer. This information allows ad networks to, among other things,
        deliver targeted advertisements that they believe will be of most interest to you. This
        privacy policy does not cover the use of cookies by any advertisers.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        4 – GOOGLE ADSENSE :
      </Typography>
      <Typography variant="body2" gutterBottom>
        Some of the ads may be served by Google. Google’s use of the DART cookie enables it to serve
        ads to Users based on their visit to our Site and other sites on the Internet. DART uses
        “non personally identifiable information” and does NOT track personal information about you,
        such as your name, email address, physical address, etc. You may opt out of the use of the
        DART cookie by visiting the Google ad and content network privacy policy at
        https://www.google.com/privacy_ads.htm
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        5- ACCEPTANCE OF THESE TERMS :
      </Typography>
      <Typography variant="body2" gutterBottom>
        By using this Site, you signify your acceptance of this policy. If you do not agree to this
        policy, please do not use our Site. Your continued use of the Site following the posting of
        changes to this policy will be deemed your acceptance of those changes.
      </Typography>
    </Page>
  );
}
