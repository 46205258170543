import React from 'react';
import PropTypes from 'prop-types';

import { CssBaseline, GlobalStyles as GlobalThemeStyles } from '@mui/material';
import { alpha, createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

export const blue = {
  50: 'hsl(210, 100%, 96%)',
  100: 'hsl(210, 100%, 90%)',
  200: 'hsl(210, 100%, 80%)',
  300: 'hsl(210, 100%, 70%)',
  400: 'hsl(210, 100%, 60%)',
  main: 'hsl(210, 100%, 45%)',
  500: 'hsl(210, 100%, 45%)',
  600: 'hsl(210, 100%, 42%)',
  700: 'hsl(210, 100%, 38%)',
  800: 'hsl(210, 100%, 30%)',
  900: 'hsl(210, 100%, 23%)',
};
export const blueDark = {
  50: 'hsl(210, 14%, 92%)',
  100: 'hsl(210, 14%, 87%)',
  200: 'hsl(210, 14%, 72%)',
  300: 'hsl(210, 14%, 56%)',
  main: 'hsl(210, 14%, 56%)',
  400: 'hsl(210, 14%, 36%)',
  500: 'hsl(210, 14%, 28%)',
  600: 'hsl(210, 14%, 22%)',
  700: 'hsl(210, 14%, 13%)',
  800: 'hsl(210, 14%, 9%)',
  900: 'hsl(210, 14%, 7%)',
};
export const grey = {
  50: 'hsl(215, 15%, 97%)',
  100: 'hsl(215, 15%, 92%)',
  200: 'hsl(215, 15%, 89%)',
  300: 'hsl(215, 15%, 82%)',
  400: 'hsl(215, 15%, 75%)',
  500: 'hsl(215, 15%, 65%)',
  600: 'hsl(215, 15%, 50%)',
  700: 'hsl(215, 15%, 40%)',
  800: 'hsl(215, 15%, 22%)',
  900: 'hsl(215, 15%, 12%)',
};
export const error = {
  50: 'hsl(355, 98%, 97%)',
  100: 'hsl(355, 98%, 93%)',
  200: 'hsl(355, 98%, 87%)',
  300: 'hsl(355, 98%, 80%)',
  400: 'hsl(355, 98%, 74%)',
  500: 'hsl(355, 98%, 66%)',
  main: 'hsl(355, 98%, 66%)',
  600: 'hsl(355, 98%, 46%)',
  700: 'hsl(355, 98%, 39%)',
  800: 'hsl(355, 98%, 29%)',
  900: 'hsl(355, 98%, 17%)',
};
export const success = {
  50: 'hsl(144, 72%, 95%)',
  100: 'hsl(144, 72%, 87%)',
  200: 'hsl(144, 72%, 77%)',
  300: 'hsl(144, 72%, 66%)',
  400: 'hsl(144, 72%, 56%)',
  500: 'hsl(144, 72%, 46%)',
  600: 'hsl(144, 72%, 41%)',
  700: 'hsl(144, 72%, 37%)',
  800: 'hsl(144, 72%, 32%)',
  900: 'hsl(144, 72%, 21%)',
};
export const warning = {
  50: 'hsl(48, 100%, 96%)',
  100: 'hsl(48, 100%, 88%)',
  200: 'hsl(48, 100%, 82%)',
  300: 'hsl(48, 100%, 64%)',
  400: 'hsl(48, 100%, 48%)',
  500: 'hsl(48, 100%, 44%)',
  main: 'hsl(48, 100%, 44%)',
  600: 'hsl(40, 100%, 40%)',
  700: 'hsl(36, 100%, 34%)',
  800: 'hsl(36, 100%, 27%)',
  900: 'hsl(36, 100%, 18%)',
};

const systemFont = [
  // "Saira",
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const defaultTheme = createTheme();

export const getMetaThemeColor = (mode) => {
  const themeColor = {
    light: blue[600],
    dark: blueDark[900],
  };
  return themeColor[mode];
};

const main = {
  shape: {
    borderRadius: 15,
  },
  typography: {
    body1: {
      fontSize: '0.9rem',
    },
    fontFamily: systemFont.join(','),
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        variant: 'outlined', // Set the default variant for Accordion
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined', // Set the default variant for Card
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined', // Set the default variant for Paper
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => [
          {
            backgroundImage: 'none',
            backgroundColor: '#fff',
            '&[href]': {
              textDecorationLine: 'none',
            },
            transition: theme.transitions.create(['border', 'box-shadow'], {
              duration: theme.transitions.duration.shortest,
            }),
            ...(ownerState.variant === 'outlined' && {
              display: 'block',
              borderColor: (theme.vars || theme).palette.grey[100],
              '&[href]': {
                textDecorationLine: 'none',
                boxShadow: `hsl(200, 0%, 100%) 0 2px 0 inset, ${alpha(theme.palette.grey[100], 0.3)} 0 -2px 0 inset, ${alpha(theme.palette.grey[200], 0.5)} 0 1px 2px 0`,
                '&:hover': {
                  borderColor: (theme.vars || theme).palette.primary[200],
                  boxShadow: `0px 2px 8px ${(theme.vars || theme).palette.primary[100]}`,
                },
                '&:focus-visible': {
                  outline: `3px solid ${alpha(theme.palette.primary[500], 0.5)}`,
                  outlineOffset: '2px',
                },
              },
              ':is(a&), :is(button&)': {
                '&:hover': {
                  borderColor: (theme.vars || theme).palette.primary[200],
                  boxShadow: `0px 4px 16px ${(theme.vars || theme).palette.grey[200]}`,
                },
              },
            }),
          },
          theme.applyDarkStyles({
            backgroundColor: (theme.vars || theme).palette.primaryDark[900],
            ...(ownerState.variant === 'outlined' && {
              borderColor: (theme.vars || theme).palette.primaryDark[700],
              backgroundColor: alpha(theme.palette.primaryDark[800], 0.6),
              '&[href]': {
                textDecorationLine: 'none',
                boxShadow: `${alpha(theme.palette.primaryDark[700], 0.4)} 0 2px 0 inset, ${(theme.vars || theme).palette.common.black} 0 -2px 0 inset, ${(theme.vars || theme).palette.common.black} 0 1px 2px 0`,
                '&:hover': {
                  borderColor: alpha(theme.palette.primary[600], 0.5),
                  boxShadow: `0px 2px 8px ${alpha(theme.palette.primary[900], 0.6)}`,
                },
              },
              ':is(a&), :is(button&)': {
                '&:hover': {
                  boxShadow: `0px 4px 24px ${(theme.vars || theme).palette.common.black}`,
                },
              },
            }),
          }),
        ],
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          padding: theme.spacing(1, 2),
          borderColor: (theme.vars || theme).palette.divider,
          ...(ownerState.variant === 'head' && {
            color: (theme.vars || theme).palette.text.primary,
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'body' && {
            color: (theme.vars || theme).palette.text.secondary,
          }),
        }),
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTopRightRadius: theme.shape.borderRadius,
          borderTopLeftRadius: theme.shape.borderRadius,
        }),
      },
    },
  },
};

export const light = {
  palette: {
    type: 'light',
    background: {
      default: '#edeef0',
      paper: '#fff',
    },
  },
  ...main,
};

export const dark = {
  palette: {
    mode: 'dark',
    // background: {
    //   paper: "rgb(17 23 29)",
    //   default: "rgb(16 18 20)",
    // },
    primary: {
      ...blue,
      main: blue[400],
    },
    secondary: {
      ...grey,
      main: blueDark[700],
      contrastText: blueDark[600],
    },
    divider: alpha(blueDark[500], 0.3),
    primaryDark: blueDark,
    background: {
      default: blueDark[900],
      paper: alpha(blueDark[800], 0.8),
    },
    common: {
      black: 'hsl(200, 10%, 4%)',
    },
    text: {
      primary: '#fff',
      secondary: grey[400],
      tertiary: grey[500],
    },
    grey: {
      ...grey,
      main: grey[700],
      contrastText: grey[600],
    },
    error,
    success: {
      ...success,
      main: success[600],
    },
    warning,
    gradients: {
      radioSubtle: `radial-gradient(100% 100% at 100% 100%, transparent 0, ${alpha(blue[900], 0.3)} 300%)`,
      linearSubtle: `linear-gradient(0deg, ${alpha(blue[900], 0.1)}, ${alpha(blueDark[900], 0.5)})`,
    },
  },

  typography: {
    fontFamily: ['"IBM Plex Sans"', ...systemFont].join(','),
    fontFamilyCode: [
      'Menlo', // macOS
      'Consolas', // Windows
      '"Droid Sans Mono"', // Linux
      'monospace', // fallback
    ].join(','),
    fontFamilyTagline: ['"General Sans"', ...systemFont].join(','),
    fontFamilySystem: systemFont.join(','),
    fontWeightSemiBold: 600,
    fontWeightExtraBold: 800,
    h1: {
      fontFamily: ['"General Sans"', ...systemFont].join(','),
      fontSize: 'clamp(2.5rem, 1.125rem + 3.5vw, 3.5em)',
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      fontFamily: ['"General Sans"', ...systemFont].join(','),
      fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
      fontWeight: 600,
      lineHeight: 44 / 36,
      letterSpacing: -0.2,
      color: grey[100],
    },
    h3: {
      fontFamily: ['"General Sans"', ...systemFont].join(','),
      fontSize: defaultTheme.typography.pxToRem(36),
      lineHeight: 44 / 36,
      letterSpacing: 0.2,
    },
    h4: {
      fontFamily: ['"General Sans"', ...systemFont].join(','),
      fontSize: defaultTheme.typography.pxToRem(30),
      lineHeight: 42 / 28,
      letterSpacing: 0.2,
    },
    h5: {
      fontSize: defaultTheme.typography.pxToRem(24),
      lineHeight: 36 / 24,
      letterSpacing: 0.1,
      color: blue[300],
    },
    h6: {
      fontSize: defaultTheme.typography.pxToRem(20),
      lineHeight: 30 / 20,
    },
    button: {
      textTransform: 'initial',
      fontWeight: 700,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: defaultTheme.typography.pxToRem(18),
      lineHeight: 24 / 18,
      letterSpacing: 0,
      fontWeight: 500,
    },
    body1: {
      fontSize: defaultTheme.typography.pxToRem(16),
      lineHeight: 24 / 16,
      letterSpacing: 0,
    },
    body2: {
      fontSize: defaultTheme.typography.pxToRem(14),
      lineHeight: 21 / 14,
      letterSpacing: 0,
    },
    caption: {
      display: 'inline-block',
      fontSize: defaultTheme.typography.pxToRem(12),
      lineHeight: 18 / 12,
      letterSpacing: 0,
      fontWeight: 700,
    },
    allVariants: {
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
  },
  applyDarkStyles(css) {
    return this.applyStyles('dark', css);
  },
  ...main,
};

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const theme = createTheme(dark);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalThemeStyles
          styles={{
            '*': {
              margin: 0,
              padding: 0,
              boxSizing: 'border-box',
            },
            html: {
              width: '100%',
              height: '100%',
              WebkitOverflowScrolling: 'touch',
            },
            body: {
              width: '100%',
              height: '100%',
            },
            '#root': {
              width: '100%',
              height: '100%',
            },
            input: {
              '&[type=number]': {
                MozAppearance: 'textfield',
                '&::-webkit-outer-spin-button': {
                  margin: 0,
                  WebkitAppearance: 'none',
                },
                '&::-webkit-inner-spin-button': {
                  margin: 0,
                  WebkitAppearance: 'none',
                },
              },
            },
            textarea: {
              '&::-webkit-input-placeholder': {
                color: theme.palette.text.disabled,
              },
              '&::-moz-placeholder': {
                opacity: 1,
                color: theme.palette.text.disabled,
              },
              '&:-ms-input-placeholder': {
                color: theme.palette.text.disabled,
              },
              '&::placeholder': {
                color: theme.palette.text.disabled,
              },
            },

            img: { display: 'block', maxWidth: '100%' },

            // Lazy Load Img
            '.blur-up': {
              WebkitFilter: 'blur(5px)',
              filter: 'blur(5px)',
              transition: 'filter 400ms, -webkit-filter 400ms',
            },
            '.blur-up.lazyloaded ': {
              WebkitFilter: 'blur(0)',
              filter: 'blur(0)',
            },
            '.stats-holder.football': {
              position: 'relative',
              width: '100%',
              margin: '0 auto',
            },

            '.stats-teams': {
              width: '100%',
              textAlign: 'center',
            },

            '.stats-teams__team': {
              width: '20%',
              position: 'relative',
            },

            '.stats-teams__label': {
              width: '60%',
            },

            '.x50': {
              width: '50%',
              position: 'relative',
            },
          }}
        />
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
