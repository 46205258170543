import React from 'react';

import { Grid } from '@mui/material';

import Roster from './Roster';
import { ILineup } from './_types';

const Lineup = React.memo(({ rst }) => (
  <Grid container spacing={2}>
    {rst[0].rst && <Roster {...rst[0]} />}
    {rst[1].rst && <Roster {...rst[1]} />}
  </Grid>
));
Lineup.prototype = ILineup;
export default Lineup;
