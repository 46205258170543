import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';
import ErrorIcon from '@/components/ErrorIcon';

import Skeleton from '@mui/material/Skeleton';

function TeamLogo({ src, alt, size }) {
  const avatarSize = size ? 64 : 24;
  const imageUrl = `https://v1.streamly.win/api/img/soccer/${src}.png?w=${
    avatarSize * 2
  }&h=${avatarSize * 2}`;

  return (
    <Image
      width={avatarSize}
      height={avatarSize}
      src={imageUrl}
      duration={500}
      errorIcon={<ErrorIcon />}
      showLoading={<Skeleton variant="circular" width={avatarSize} height={avatarSize} />}
      alt={`${alt} Soccer Streams`}
    />
  );
}

TeamLogo.propTypes = {
  alt: propTypes.string.isRequired,
  size: propTypes.bool.isRequired,
  src: propTypes.string.isRequired,
};

export default React.memo(TeamLogo);
