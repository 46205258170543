import React from 'react';
import propTypes from 'prop-types';
import Game from '@/views/Home/Competition/Game';

import { List, ListSubheader, Paper } from '@mui/material';
import { AccordionLeagueSx } from '@/styles';

export default function GamesListCard({ games, title, league, isTop }) {
  if (!games?.length) {
    return null;
  }

  return (
    <List
      sx={AccordionLeagueSx}
      component={Paper}
      subheader={<ListSubheader component="div">{title || 'Games'}</ListSubheader>}
    >
      {games?.map((game) => (
        <Game
          key={`top-games${game.i}`}
          gameId={game.i}
          isTop={isTop}
          status={game.s}
          name={game.n}
          slug={game.l || league}
          date={game.d}
          teams={game.c}
        />
      ))}
    </List>
  );
}

GamesListCard.propTypes = {
  games: propTypes.arrayOf(propTypes.any),
  isTop: propTypes.bool,
  league: propTypes.string,
  title: propTypes.string,
};
