import React from 'react';

import SwapVert from '@mui/icons-material/SwapVert';

import { ISub } from '../_types';

const Subbed = React.memo(({ jsy, ath: { i, dn }, getAthlete }) => (
  <span style={{ color: '#3d91fd' }}>
    <SwapVert color="inherit" fontSize="small" style={{ verticalAlign: 'middle' }} />
    {jsy} {getAthlete(i).lsn || dn}
  </span>
));

Subbed.prototype = ISub;
export default Subbed;
