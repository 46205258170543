import React from 'react';
import Image from 'mui-image';
import dayjs from '@/utils/dayJs';
import { Link } from 'react-router-dom';
import useLeague from '@/hooks/useLeague';

import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import StadiumIcon from '@mui/icons-material/Stadium';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ListSubheader from '@mui/material/ListSubheader';

export default function MatchInfo({ slug, d, v, isLoading = true }) {
  const leagueSlug = slug.replaceAll('-', '.');
  const league = useLeague(leagueSlug);
  const gameTime = dayjs(d).format('MMM D, YYYY h:mm A');
  const avatar = React.useMemo(
    () => (
      <Image
        alt={`${league?.name} Soccer Streams`}
        width={40}
        height={40}
        duration={500}
        showLoading={<Skeleton width={40} height={40} />}
        src={`https://v1.streamly.win/api/img/football/league/${leagueSlug}?w=80&h=80`}
      />
    ),
    [league?.name, leagueSlug]
  );
  return (
    <List
      component={Paper}
      subheader={
        <ListSubheader component="div">
          {isLoading ? <Skeleton width={100} /> : 'Game Info'}
        </ListSubheader>
      }
    >
      <ListItem divider component="div">
        <ListItemIcon>{isLoading ? <Skeleton width={40} height={40} /> : avatar}</ListItemIcon>
        <ListItemText
          primary={
            isLoading ? (
              <Skeleton width={135} />
            ) : (
              <Typography color="textPrimary" component={Link} to={`/league/${slug}`}>
                {league?.n}
              </Typography>
            )
          }
          secondary={isLoading ? <Skeleton width={80} /> : league?.subtitle || 'Competition'}
        />
      </ListItem>

      <ListItem divider component="div">
        <ListItemIcon>
          {isLoading ? <Skeleton width={40} height={40} /> : <ScheduleIcon fontSize="large" />}
        </ListItemIcon>
        <ListItemText
          primary={isLoading ? <Skeleton width={154} /> : gameTime}
          secondary={isLoading ? <Skeleton width={52} /> : 'Kick Off'}
        />
      </ListItem>
      <ListItem component="div">
        <ListItemIcon>
          {isLoading ? <Skeleton width={40} height={40} /> : <StadiumIcon fontSize="large" />}
        </ListItemIcon>
        <ListItemText
          primary={isLoading ? <Skeleton width={100} /> : v?.fn}
          secondary={isLoading ? <Skeleton width={42} /> : 'Venue'}
        />
      </ListItem>
    </List>
  );
}
