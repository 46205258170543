import React from 'react';
import dayjs from '@/utils/dayJs';
import propTypes from 'prop-types';

import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';

function Time({ date, status, homeScore, awayScore }) {
  const state = React.useMemo(() => {
    if (status.cm) {
      return 'FT';
    }
    if (status.st === 'in') {
      return (
        <Typography component="span" variant="h6" fontWeight="bold" color="warning">
          {status.dc || status.dt || 'Live'}
        </Typography>
      );
    }
    if (status.st === 'pre') {
      return <AccessTime fontSize="small" />;
    }
    if (status.n === 'STATUS_POSTPONED') {
      return 'Postp.';
    }
    return null;
  }, [status]);
  return (
    <Grid
      size={{ sm: 2, xs: 4 }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Typography variant="body2" color="textSecondary" noWrap sx={{ fontWeight: 'bold' }}>
        {state}
      </Typography>
      {(status.st === 'in' || status.cm) && (
        <Typography>
          <Typography
            variant="h4"
            component="span"
            color={homeScore > awayScore ? 'textPrimary' : 'textSecondary'}
            fontWeight="bold"
            noWrap
          >
            {homeScore}
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="textSecondary" component="span">
            {' '}
            -{' '}
          </Typography>
          <Typography
            variant="h4"
            component="span"
            color={homeScore < awayScore ? 'textPrimary' : 'textSecondary'}
            fontWeight="bold"
            noWrap
          >
            {awayScore}
          </Typography>
        </Typography>
      )}

      {(status.st === 'pre' || status.cm) && (
        <Typography variant="body1" noWrap>
          {status.st === 'pre' && dayjs(date).format('HH:mm')}
        </Typography>
      )}
    </Grid>
  );
}

Time.propTypes = {
  awayScore: propTypes.string,
  date: propTypes.string,
  homeScore: propTypes.string,
  status: propTypes.shape({
    cm: propTypes.bool,
    st: propTypes.string,
    n: propTypes.string,
  }),
};

export default React.memo(Time);
