import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { topTeams } from '@/utils/constants';
import { filterGame } from '@/utils/filters';
import GamesListCard from '@/views/Home/GamesListCard';

// Selector to extract the posts data from the API slice
const selectGames = [
  (state) => state.api.queries?.['getGames(undefined)']?.data,
  (state) => state.main.gameFilter,
];

// Selector to filter posts based on a specific criteria
const selectGamesWithTops = createSelector(selectGames, (data, filter) => {
  if (!data?.length) {
    return [];
  }
  return data
    .flatMap((league) => league.gs)
    .filter((game) => filterGame(game, filter))
    .filter(({ c: [h, a] }) => topTeams.includes(h.i) || topTeams.includes(a.i));
  // .filter((game) => !game.s.tp.cm
});

// const listSubSx = (theme) => ({borderBottom: `1px solid ${theme.palette.divider}`});

export default function TopGames() {
  const games = useSelector(selectGamesWithTops);

  if (!games?.length) {
    return null;
  }

  return <GamesListCard games={games} isTop title="Top Games" />;
}
