import * as React from 'react';
import propTypes from 'prop-types';

import List from '@mui/material/List';
import { colors } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import ListSubheader from '@mui/material/ListSubheader';
import TableContainer from '@mui/material/TableContainer';

const StyledTableRow = styled(TableRow)(({ theme, active }) => ({
  ...(active
    ? {
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
      }
    : {}),
  '&:last-child td': {
    border: 0,
  },
  '& td, & th': {
    color: active ? theme.palette.text.primary : theme.palette.text.secondary,
    fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
  },
}));

const colorsMap = {
  '#81D6AC': colors.blue[500],
  '#B2BFD0': colors.red[500],
  '#B5E7CE': colors.orange[500],
  '#FF7F84': colors.grey[800],
};

const RankTableCell = styled(TableCell)(({ active, color }) => ({
  paddingLeft: 0,
  '&::before': {
    marginRight: 12,
    content: '""',
    borderWidth: '0px 0px 0px 5px',
    borderStyle: 'solid',
    borderColor: active ? '#635ee7' : colorsMap[color] || color || 'transparent',
    height: 24,
    borderRadius: '0 15px 15px 0',
  },
}));

const TeamTableItem = React.memo(({ stt, t, nt, active }) => {
  const statsByKey = React.useMemo(
    () =>
      stt.reduce((acc, item) => {
        acc[item.n] = item;
        return acc;
      }, {}),
    [stt]
  );

  return (
    <StyledTableRow active={active} key={t.n || t}>
      <RankTableCell color={nt?.c} active={active}>
        {statsByKey.rank.dv}
      </RankTableCell>
      <TableCell>
        {t.n || t}
        <Typography variant="caption" component="span" sx={{ ml: 2 }}>
          {statsByKey.overall.dv}
        </Typography>
      </TableCell>
      {['gamesPlayed', 'pointDifferential', 'points'].map((item) => (
        <TableCell key={item} align="center">
          {statsByKey[item].dv}
        </TableCell>
      ))}
    </StyledTableRow>
  );
});

TeamTableItem.propTypes = {
  active: propTypes.bool,
  stt: propTypes.array,
  t: propTypes.string,
};

function DataTable({ data, activeIds = [] }) {
  const table = React.useMemo(
    () =>
      Object.prototype.hasOwnProperty.call(data, 'stg') &&
      Object.prototype.hasOwnProperty.call(data.stg, 'entries')
        ? data.stg.entries
        : data.stg,
    [data]
  );

  return (
    <List
      component={Paper}
      subheader={<ListSubheader component="div">{data?.n || data?.dn || data?.hdr}</ListSubheader>}
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <TableCell>#</TableCell>
              <TableCell>Team</TableCell>
              <TableCell align="center">GP</TableCell>
              <TableCell align="center">GD</TableCell>
              <TableCell align="center">PT</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {table?.map((item) => (
              <TeamTableItem
                key={item.t?.n || item.t}
                active={activeIds.includes(item.i)}
                t={item.t}
                nt={item.nt}
                stt={item.stt}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </List>
  );
}

DataTable.propTypes = {
  activeIds: propTypes.array,
  data: propTypes.array,
  hdr: propTypes.string,
};

function LeagueTable({ data, activeIds = [] }) {
  return data?.map((item) => <DataTable key={`Table-${item}`} data={item} activeIds={activeIds} />);
}

LeagueTable.propTypes = {
  activeIds: propTypes.array,
  activeStanding: propTypes.number,
  data: propTypes.array,
};

export default LeagueTable;
