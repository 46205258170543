import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { homeGameSx, homeGameButtonSx } from '@/styles';

import Team from './Team';
import Time from './Time';
import Score from './Score';

function Game({ slug, isH2H, isTop, teams, date, gameId, status, name }) {
  const [home, away] = teams;
  const isLive = status.tp.st === 'in';

  const formattedSlug = slug.replaceAll('.', '-');
  const formattedName = name || `${home.t.sd} vs ${away.t.sd}`;
  const encodedName = formattedName.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase();

  const link = `/event/${formattedSlug}/${encodedName}-live-soccer-stats/${gameId}`;

  return (
    <ListItem
      key={name}
      sx={homeGameSx}
      to={link}
      title={`${formattedName} live soccer streams`}
      component={Link}
      disableGutters
      disablePadding
    >
      <ListItemButton disableRipple sx={(theme) => homeGameButtonSx(theme, isLive)}>
        <Time date={date} isTop={isTop} isH2H={isH2H} slug={slug} status={status} />
        <Grid sx={{ flex: '1 1' }} container direction="column" alignItems="center" spacing={1}>
          <Team isWinner={home.sc > away.sc} team={home} />
          <Team isWinner={home.sc < away.sc} team={away} />
        </Grid>
        <Score date={date} status={status} homeScore={home.sc} awayScore={away.sc} />
      </ListItemButton>
    </ListItem>
  );
}

Game.propTypes = {
  date: propTypes.string.isRequired,
  gameId: propTypes.string.isRequired,
  isH2H: propTypes.bool,
  isTop: propTypes.bool,
  name: propTypes.string,
  slug: propTypes.string.isRequired,
  status: propTypes.shape({
    displayClock: propTypes.string.isRequired,
    type: {
      completed: propTypes.string.isRequired,
      state: propTypes.string.isRequired,
    },
  }),
  teams: propTypes.arrayOf(
    propTypes.shape({
      ha: propTypes.string.isRequired,
      i: propTypes.string.isRequired,
      o: propTypes.number.isRequired,
      sc: propTypes.string.isRequired,
      t: {
        n: propTypes.string.isRequired,
        ab: propTypes.string.isRequired,
        i: propTypes.string.isRequired,
        sd: propTypes.string.isRequired,
      },
    })
  ),
};

export default React.memo(Game);
