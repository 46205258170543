import React from 'react';
import propTypes from 'prop-types';

import Typography from '@mui/material/Typography';

function TeamName({ name, isWinner }) {
  return (
    <Typography
      color={isWinner ? 'textPrimary' : 'textSecondary'}
      variant="body1"
      fontWeight={isWinner ? 'bold' : 'normal'}
      noWrap
    >
      {name}
    </Typography>
  );
}

TeamName.propTypes = {
  isWinner: propTypes.bool,
  name: propTypes.string.isRequired,
};

export default React.memo(TeamName);
