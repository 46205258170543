import * as React from 'react';
import Loadable from '@/components/Loadable';
import MainLayout from '@/layout/MainLayout';
import ScoreLayout from '@/layout/ScoreLayout';
import { createBrowserRouter } from 'react-router-dom';
// import League from "@/views/League";
// import Leagues from "@/views/Leagues";
// import Match from "@/views/Match";
// import NewsList from "@/views/News/NewsList";
// import Sitemap from "@/views/Sitemap";
// import SitemapLink from "@/views/Socccerstreams";

// dashboard routing
// const Home = Loadable(lazy(() => import('../views/Home')));
const NotFoundHero = Loadable(React.lazy(() => import('../views/NotFoundHero')));

// ==============================|| MAIN ROUTING ||============================== // <Nf />

export const mainRoutes = createBrowserRouter([
  {
    path: '/',
    Component: MainLayout,
    // errorElement: <NotFoundHero />,
    children: [
      {
        Component: ScoreLayout,
        children: [
          {
            index: true,
            lazy: () => import('../views/Home'),
          },
          {
            path: '/event/:slug/:title/:id/:tab?',
            lazy: () => import('../views/Match'),
          },
          {
            path: '/league/:slug/:tab?',
            lazy: () => import('../views/League'),
          },
          {
            path: '/leagues',
            lazy: () => import('../views/Leagues'),
          },
        ],
      },
      {
        path: '/news',
        lazy: () => import('../views/News/NewsList'),
      },
      {
        path: '/news/:id',
        lazy: () => import('../views/News/Post'),
      },
      {
        path: '/sitemap',
        lazy: () => import('../views/Sitemap'),
      },
      {
        path: '/soccerstreams/:id',
        lazy: () => import('../views/Socccerstreams'),
      },

      {
        path: '/contact',
        lazy: () => import('../views/Contact'),
      },
      {
        path: '/rules',
        lazy: () => import('../views/Rules'),
      },
      {
        path: '/dcma',
        lazy: () => import('../views/Dcma'),
      },
      {
        path: '/privacy',
        lazy: () => import('../views/Privacy'),
      },
      {
        path: '/advertise',
        lazy: () => import('../views/Advertise'),
      },
      {
        path: '*',
        Component: NotFoundHero,
      },
    ],
  },
]);
