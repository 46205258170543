export const topTeams = [
  '367',
  '364',
  '359',
  '382',
  '360',
  '363',
  '86',
  '83',
  '1068',
  '111',
  '103',
  '104',
  '110',
  '114',
  '132',
  '124',
  '160',
  '176',
];

export const topLeagues = {
  'uefa.champions': 'Champions League',
  'eng.1': 'Premier League',
  'uefa.europa': 'Europa League',
  'esp.1': 'LaLiga Santander',
  'uefa.europa.conf': 'UEFA Europa Conference',
  'ita.1': 'Serie A',
  'ger.1': 'Bundesliga',
  'fra.1': 'Ligue 1',
  'usa.1': 'MLS',
  'fifa.world': 'FIFA World Cup',
};

export const alternativeSites = [
  {
    name: 'Soccer',
    url: 'https://streamsgate.tv/',
    logo: '/img/soccer.png',
  },
  {
    name: 'NBA',
    url: 'https://new.nbastreams-100.tv/',
    logo: '/img/nba.png',
  },
  {
    name: 'NFL',
    url: 'https://m.nflstreams100.com/',
    logo: '/img/nfl.png',
  },
  {
    name: 'MLB',
    url: 'https://new.mlbstreams100.com/',
    logo: '/img/mlb.png',
  },
  {
    name: 'NHL',
    url: 'https://new.nhlstreams100.com/',
    logo: '/img/nhl.png',
  },
  {
    name: 'MMA',
    url: 'https://red.mmastreams-100.tv/',
    logo: '/img/mma.png',
  },
  {
    name: 'Boxing',
    url: 'https://boxingstreams100.com/new',
    logo: '/img/box.png',
  },
  {
    name: 'F1',
    url: 'https://main.f1streams100.com/',
    logo: '/img/f1.png',
  },
  {
    name: 'MotoGP',
    url: 'https://main.motogpstreams100.com/',
    logo: '/img/motogp.png',
  },
];

export const gamesStates = ['All', 'Live', 'Next', 'Done'];

export const headerMenu = {
  '/': {
    label: 'Home',
    isExternal: false,
  },
  '/news': {
    label: 'News',
  },
  '/advertise': {
    label: 'Advertise',
    isExternal: false,
  },
  /* 'https://streamer.soccerstreams-100.tv/': {
      label: 'Submit',
      isExternal: true,
      disabled: true,
    }, */
  '/rules': {
    label: 'Rules',
    isExternal: false,
  },
  '/contact': {
    label: 'Contact',
    isExternal: false,
  },
  '/dcma': {
    label: 'Dcma',
    isExternal: false,
  },
  '/privacy': {
    label: 'Privacy',
    isExternal: false,
  },
};
